import React from "react";
import { FlexColumn, Card } from "../Common/styles.common";
import DDPoHeader from "../Header/DDPoHeader";


const InvoiceUploaded = ({ po, items, tonnage, pageSource }) => {
    return (
        <React.Fragment>
            <DDPoHeader
                poId={po?.id}
                purchaseOrderNumber={po?.purchase_order_number}
                itemsLength={items?.length}
                tonnage={tonnage}
                source={pageSource}
            />
            <Card className="mt-4 mx-4">
                <FlexColumn className="align-items-center p-5">
                    <img src="/logos/tick_logo.svg" height="80px" width="80px" />
                    <span className="fs-22 fw-700 my-4">Invoice already uploaded</span>
                    <p className="fw-600 ml-4">New invoice already uploaded using this link. Please wait for Hyperpure finance team to check the uploaded invoice and get back in case of any issue.</p>
                </FlexColumn>
            </Card>
        </React.Fragment>
    )
};

export default InvoiceUploaded;