import React, {useEffect, useState, useRef} from 'react';
import DDPoHeader from "../Header/DDPoHeader";
import ViewPDF from "../../../utils/ViewPDF";
import {Button, FlexColumn, SushiModal, WhiteWrapper, Wrapper} from "../Common/styles.common";
import useVendorStore from "../HomePage/useVendorStore";
import {useNavigate} from "react-router-dom";
import {STATUS_BEGIN, STATUS_DONE, STATUS_FAIL} from "../../../utils/constants/others";
import useCommonStore from "../Common/useCommonStore";
import {Loader} from "../Common/Loader";
import DownloadIcon from "sushiweb/Icons/all/Download";
import {DownloadInvoice} from "../../../operations/operations";
import Cross from "sushiweb/Icons/all/Cross";
import ShowToast from "../Common/Toast";

const PreviewInvoice = (props) => {
    const {
        poId,
        purchaseOrderNumber,
        itemsLength,
        tonnage,
        pdfBlob,
        setPdfBlob,
        invoiceDetails,
        setShowFile,
        formData,
        po,
    } = props;

    const updatePurchaseOrder = useVendorStore((state) => state.updatePurchaseOrder);
    const purchaseOrderStatus = useVendorStore((state)=> state.updatePurchaseOrderStatus);
    const markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus = useVendorStore((state)=> state.markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus);
    const markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus = useVendorStore((state)=> state.markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus);
    const markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder = useVendorStore((state) => state.markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder)
    const uploadOrderBillDocument = useCommonStore((state) => state.uploadOrderBillDocument)
    const markCompleteBuyerOrderLinkedToDDPurchaseOrder = useVendorStore((state) => state.markCompleteBuyerOrderLinkedToDDPurchaseOrder)
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showErrorDataModal, setShowErrorDataModal] = useState(false);
    const updatePurchaseOrderError = useVendorStore((state) => state.updatePurchaseOrderError);
    const markBuyerOrderCompletedLinkedToDDPurchaseOrderError = useVendorStore((state) => state.markBuyerOrderCompletedLinkedToDDPurchaseOrderError);
    const updatePurchaseOrderErrorModalData = useVendorStore((state) => state.updatePurchaseOrderErrorModalData);
    const getBuyerOrderPodDetails = useVendorStore((state) => state.getBuyerOrderPodDetails);
    const getBuyerOrderPodDetailsStatus = useVendorStore((state) => state.getBuyerOrderPodDetailsStatus);
    const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO);
    const isFirstRender = useRef(true);

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        setShowFile(false)
        navigate("/ddPo/all");
        window.location.reload();
    };

    useEffect(() => {
        getBuyerOrderPodDetails({
            purchase_order_id: po.id,
        })
    } ,[])

    useEffect(() => {
        if (purchaseOrderStatus === STATUS_DONE && !isFirstRender.current) {
            if (po.is_multiple_grn_enabled === true){
                completeBos();
            }else {
                setShowLoader(false);
            }
        } else if (purchaseOrderStatus === STATUS_FAIL) {
            setShowLoader(false);
            setShowToast(true);
            setShowErrorDataModal(true)
        }
    }, [purchaseOrderStatus]);


    useEffect(() => {
        if (isFirstRender.current){
            isFirstRender.current = false;
            return;
        }
        if (markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_FAIL || markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
            setOpenConfirmation(true);
        }
    }, [markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus])

    useEffect(() => {
        if (markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
            setOpenConfirmation(true);
        } else if (markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_FAIL) {
            setShowLoader(false);
            setShowToast(true);
        }
    }, [markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus]);

    const completeBos = () => {
        if (buyerOrdersLinkedtoPO.length > 0) {
          let orderIds = buyerOrdersLinkedtoPO.map((bo) => bo.buyer_order_id);
          markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder({
            order_ids : orderIds,
            purchase_order_id : po.id,
            warehouse_code : po.warehouse_code,
          });
        }
    }

    const closeErrorModal = () => {
        setShowErrorDataModal(false);
        setShowToast(false);
    };

    const handleMarkCompleteBuyerOrderLinkedToDDPurchaseOrder = () => {
        let po = props.po;
        if(po.order_tag === "DIRECT_DELIVERY" && po.is_multiple_grn_enabled === true && po.ref_order_number && po.ref_order_number.length > 0 && po.ref_order_number != "MERCHANT_BULK" ) {
            markCompleteBuyerOrderLinkedToDDPurchaseOrder({
                order_number: po.ref_order_number,
                warehouse_code: po.warehouse_code,
            })
        } else {
            setOpenConfirmation(true);
        }
    }

    const handleConfirmClick = () => {
        setShowLoader(true);
        uploadOrderBillDocument(formData, updatePurchaseOrder, invoiceDetails, poId)
    }

    const downloadDeliveryInvoices = (invoice, deliveryChallan, orderNumber) => {
        if (invoice.length > 0) {
            downloadPdfFromS3(invoice, orderNumber + "-Invoice");
        }
        if (deliveryChallan.length > 0) {
            downloadPdfFromS3(deliveryChallan, orderNumber + "-Delivery-challan");
        }
    }

    function downloadPdfFromS3(s3Url, filename) {
        DownloadInvoice(s3Url, filename);
        if (window && window.downloadFileMessageHandler) {
            window.downloadFileMessageHandler.postMessage(s3Url);
        }
    }

    return (
        <React.Fragment>
        {showLoader ? <Loader /> :
        (<Wrapper>
            <DDPoHeader
                poId={poId}
                purchaseOrderNumber={purchaseOrderNumber}
                itemsLength={itemsLength}
                tonnage={tonnage}
                source="details"
            ></DDPoHeader>
            <div>
                <ViewPDF pdfBlob={pdfBlob} setPdfBlob={setPdfBlob}/>
            </div>
            <WhiteWrapper className="po-bottom">
                <Button
                    className="fs-13 text-white fw-600"
                    onClick={() => handleConfirmClick()}
                >
                    Click to confirm
                </Button>
                <SushiModal visible={openConfirmation} isCentered>
                    <FlexColumn className="align-items-center p-5">
                        <div className="align-self-end" onClick={closeConfirmation} > <Cross /> </div>
                        <img src="/logos/tick_logo.svg" height="80px" width="80px" />
                        <span className="fs-22 fw-700 my-4">Invoice uploaded</span>
                        {
                            po?.dd_order_details?.buyer_order_invoice.length > 0 || po?.dd_order_details?.delivery_challan.length > 0 ?
                                <div onClick={() => downloadDeliveryInvoices(po.dd_order_details?.buyer_order_invoice, po.dd_order_details?.delivery_challan, po.ref_order_number)}>
                                    <Button
                                        className="fs-12 text-white fw-600 d-flex align-items-center justify-content-between"
                                    >
                                        <DownloadIcon />
                                        <span className="ml-2 fs-14 fw-500"> Download delivery docs </span>
                                    </Button>
                                </div>
                                :
                                <span
                                    className="fs-14 fw-500 text-red-500"
                                    onClick={closeConfirmation}
                                >
                                    {" "}
                                    Okay
                                </span>
                        }
                    </FlexColumn>
                </SushiModal>
                <SushiModal visible={showErrorDataModal && updatePurchaseOrderErrorModalData && updatePurchaseOrderErrorModalData.title && updatePurchaseOrderErrorModalData.title.length > 0} isCentered>
                    <FlexColumn className="align-items-center p-5">
                        <div> </div>
                        <img src="/logos/cross-circle-fill.svg" height="80px" width="80px" />
                        <div className="text-align-center my-4"  >
                            <span className="fs-22 fw-700 my-4">{updatePurchaseOrderErrorModalData && updatePurchaseOrderErrorModalData.title}</span>
                        </div>
                        {
                           updatePurchaseOrderErrorModalData && updatePurchaseOrderErrorModalData.subtitle && updatePurchaseOrderErrorModalData.subtitle.length > 0 ?
                            <div className="text-align-center my-4 subtitle-background"  >
                                <span className="fs-13 fw-700 my-4 text-gray-cool">{updatePurchaseOrderErrorModalData.subtitle}</span>
                            </div>
                            : <></>
                        }
                        <span
                            className="fs-14 fw-500 text-red-500 mt-4"
                            onClick={()=>closeErrorModal()}
                        >Okay</span>
                    </FlexColumn>
                </SushiModal>
                {showToast && !updatePurchaseOrderErrorModalData ?
                    <ShowToast
                        errorMsg={markBuyerOrderCompletedLinkedToDDPurchaseOrderError || updatePurchaseOrderError}
                    />
                    :
                    <></>
                }
            </WhiteWrapper>
        </Wrapper>)}
        </React.Fragment>
    );
};

export default PreviewInvoice;
