import React, { useState } from "react";
import Toast from "sushiweb/Toast";

const ShowToast = (props) => {
    const {
        errorMsg, toastMsg
    } = props;
    const [show,setShow] = useState(true)

    const handleClose=()=>{
        setShow(false)
    }

    return(
        <React.Fragment>
            <Toast
                show={show}
                align="bottom"
                onClose={handleClose}
                text={toastMsg?.length > 0 ? toastMsg : errorMsg?.length > 0 ? errorMsg : "Something went wrong"}
                type={toastMsg?.length > 0 ?"success" : "error"}
                autoHideDuration={5}
            />
        </React.Fragment>
    )
}

export default ShowToast;