import React, { useEffect, useState } from 'react';
import DDPoHeader from "../Header/DDPoHeader";
import ViewPDF from "../../../utils/ViewPDF";
import { Button, FlexColumn, SushiModal, WhiteWrapper, Wrapper } from "../Common/styles.common";
import useVendorStore from "../HomePage/useVendorStore";
import { useNavigate, useParams } from "react-router-dom";
import {STATUS_DONE, STATUS_FAIL , REUPLOAD_VENDOR_INVOICE_STATUS_COMPLETED } from "../../../utils/constants/others";
import useCommonStore from "../Common/useCommonStore";
import { Loader } from "../Common/Loader";
import Cross from "sushiweb/Icons/all/Cross";
import ShowToast from "../Common/Toast";

const PreviewReuploadedInvoice = (props) => {
    const {
        poId,
        purchaseOrderNumber,
        itemsLength,
        tonnage,
        pdfBlob,
        setPdfBlob,
        invoiceDetails,
        setShowFile,
        presignedUrl,
        documentPath
    } = props;
    let { grnNumber, referenceNumber } = useParams();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const updateGrnDocumentUpdateData = useVendorStore((state) => state.updateGrnDocumentUpdateData);
    const updateGrnDocumentUpdateDataStatus = useVendorStore((state) => state.updateGrnDocumentUpdateDataStatus);
    const updateGrnDocumentUpdateDataError = useVendorStore((state) => state.updateGrnDocumentUpdateDataError);
    const uploadFileToS3 = useCommonStore((state) => state.uploadFileToS3);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        setShowLoader(false);
        if (updateGrnDocumentUpdateDataStatus === STATUS_DONE) {
            setOpenConfirmation(true);
        } else if (updateGrnDocumentUpdateDataStatus === STATUS_FAIL) {
            setShowToast(true);
            setErrMsg(updateGrnDocumentUpdateDataError);
        }
    }, [updateGrnDocumentUpdateDataStatus]);

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        setShowFile(false)
        navigate("/");
        window.location.reload();
    };

    const handleSuccessEvent = () => {
        updateGrnDocumentUpdateData({
            reference_number: referenceNumber,
            grn_number: grnNumber,
            invoice_number: invoiceDetails?.invoice_number,
            invoice_amount: parseFloat(invoiceDetails?.invoice_amount),
            document_Path: documentPath,
            status: REUPLOAD_VENDOR_INVOICE_STATUS_COMPLETED
        });
    }

    const handleFailureEvent = () => {
        setShowLoader(false);
        setErrMsg("Error in uploading invoice. Please try again later.");
        setShowToast(true);
    }

    const handleConfirmClick = () => {
        setShowLoader(true);
        uploadFileToS3(
            pdfBlob,
            presignedUrl,
            handleSuccessEvent,
            handleFailureEvent
        );
    }

    return (
        <React.Fragment>
            {showLoader ? <Loader /> :
                (<Wrapper>
                    <DDPoHeader
                        poId={poId}
                        purchaseOrderNumber={purchaseOrderNumber}
                        itemsLength={itemsLength}
                        tonnage={tonnage}
                        source="details"
                    ></DDPoHeader>
                    <div>
                        <ViewPDF pdfBlob={pdfBlob} setPdfBlob={setPdfBlob} />
                    </div>
                    <WhiteWrapper className="po-bottom">
                        <Button
                            className="fs-13 text-white fw-600"
                            onClick={() => handleConfirmClick()}
                        >
                            Click to confirm
                        </Button>
                        <SushiModal visible={openConfirmation} isCentered>
                            <FlexColumn className="align-items-center p-5">
                                <div className="align-self-end" onClick={closeConfirmation} > <Cross /> </div>
                                <img src="/logos/tick_logo.svg" height="80px" width="80px" />
                                <span className="fs-22 fw-700 my-4">Invoice uploaded</span>
                                <span
                                    className="fs-14 fw-500 text-red-500"
                                    onClick={closeConfirmation}
                                >
                                    {" "}
                                    Okay
                                </span>
                            </FlexColumn>
                        </SushiModal>
                        {showToast ?
                            <ShowToast
                                errorMsg={errMsg}
                            />
                            :
                            <></>
                        }
                    </WhiteWrapper>
                </Wrapper>)}
        </React.Fragment>
    );
};

export default PreviewReuploadedInvoice;
