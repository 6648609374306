import { FlexColumn, SpinLoader } from "../Common/styles.common";
import React from "react";
import Spinner from "sushiweb/Spinner/Spinner";
export const Loader = ({isScrollLoader}) => {
  return (
    <React.Fragment>
      {isScrollLoader?
      <div className="d-flex justify-content-center">
        <Spinner size="small" color="#EF4F5F"/>
      </div>
      :
      <SpinLoader>
        <Spinner size="large" color="#EF4F5F" />
      </SpinLoader>
      }
    </React.Fragment>
  );
};
