import React, { useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useEffect } from "react";
import { Wrapper, WhiteWrapper } from "../Common/styles.common";
import { map } from "lodash";
import { GrnCard } from "./GrnCard";
import { Loader } from "../Common/Loader";
import { GRN_STATUS_COMPLETED, PAYMENT_STATUS_PENDING, SELLER_STATUS_NON_APPROVED, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";
import InfiniteScroll from "react-infinite-scroll-component";
import BackButton from "../Button/BackButton";
import { useNavigate } from "react-router-dom";
import ShowToast from "../Common/Toast";

const PendingGrn = () => {
  const poGrnMappings = useVendorStore((state) => state.poGrnList);
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const getPoGrnMappingStatus = useVendorStore(
    (state) => state.getPoGrnMappingStatus
  );
  const grnCount = useVendorStore((state)=> state.grnCount)
  const outletId = window.localStorage.getItem("outlet_id");
  const [showLoader, setShowLoader] = useState(false);
  const [pageNumber,setPageNumber] = useState(1)
  const [showToast,setShowToast] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    getPoGrnMappings({
      page_number: 1,
      grn_status: GRN_STATUS_COMPLETED,
      page_size: 20,
      seller_status: SELLER_STATUS_NON_APPROVED,
      outlet_id: outletId,
      is_po_grn_items_required: true,
      payment_status: PAYMENT_STATUS_PENDING
    },true);
  }, []);

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPoGrnMappingStatus === STATUS_DONE) {
      setShowLoader(false);
    }
    if ( getPoGrnMappingStatus === STATUS_FAIL) {
      setShowLoader(false);
      setShowToast(true);
    }
  }, [getPoGrnMappingStatus]);

  useEffect(()=>{
    if(grnCount===0){
      navigate("/")
    }
  },[grnCount])

  let cards = [];

  if (poGrnMappings && poGrnMappings.length) {
    for (let index = 0; index < poGrnMappings.length; index++) {
      cards.push(<GrnCard key={index} grn={poGrnMappings[index]} source={"pending"}/>);
    }
  }

  const loadNextPage = () => {
    getPoGrnMappings({
        page_number: pageNumber+1,
        grn_status: GRN_STATUS_COMPLETED,
        page_size: 20,
        seller_status: SELLER_STATUS_NON_APPROVED,
        outlet_id: outletId,
        is_po_grn_items_required: true,
        payment_status: PAYMENT_STATUS_PENDING
      },false);
    setPageNumber(pageNumber+1)
  }

  return (
    <React.Fragment>
      {showLoader && pageNumber===1 ? (
        <Loader />
      ) : (
        <Wrapper className="d-flex flex-column">
          <WhiteWrapper>
            <div className="d-flex">
                <BackButton pathName={"/"}/>
                <span className="fs-16 fw-600">GRN Pending confirmation</span>
            </div>
          </WhiteWrapper>
          <InfiniteScroll
            className="infinite-scroll"
            height={"93%"}
            dataLength={cards.length}
            hasMore={cards.length < grnCount}
            next={loadNextPage}
            loader={<Loader isScrollLoader={true}/>}
          >
        {cards}
          </InfiniteScroll>
          
        </Wrapper>
      )}
      {showToast?
        <ShowToast/>
        :
        <></>
      }
    </React.Fragment>
  );
};

export default PendingGrn;
