import styled from "styled-components";

export const ErrorDisp = styled.div`
  position: relative;
  top: 10px;
`;

export const Spinner = styled.div`
  display: flex;
`;
