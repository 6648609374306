import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton =({pathName}) =>{
    
    const navigate = useNavigate();
    const handleBack = () => {
        navigate({ pathname: pathName });  
    }

    return(
        <React.Fragment>
            <img
                src={"/logos/back_logo.svg"}
                className="mr-4"
                onClick={handleBack}
            />
        </React.Fragment>
    )
}

export default BackButton;