import React, {useEffect, useState} from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useParams } from "react-router-dom";
import {
    Button, FlexColumn, SeparatorLine, SushiModal,
    WhiteWrapper,
    Wrapper,
} from "../Common/styles.common";
import { Loader } from "../Common/Loader";
import {STATUS_BEGIN, STATUS_DONE, STATUS_FAIL} from "utils/constants/others";
import DDPoHeader from "../Header/DDPoHeader";
import styles from "../Login/Login.module.scss";
import OtpInput from "react-otp-input";
import {errorDisp} from "../Login/styles.login";
import {useNavigate} from "react-router-dom";
import Cross from "sushiweb/Icons/all/Cross";
import redCross from "./PodValidationImages/Cross.svg";
import greenCheck from "./PodValidationImages/Check.svg";
import WhiltelistedFileTypes from "../Common/Logout";

const DDPodFormV2 = (props) => {
    const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
    const getPurchaseOrderStatus = useVendorStore(
        (state) => state.getPurchaseOrderStatus
    );
    const validatePodOtp = useVendorStore((state) => state.validatePodOtp);
    const validatePodOtpStatus = useVendorStore(
        (state) => state.validatePodOtpStatus
    );
    const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
    const [po, setPo] = useState("");
    const [items, setItems] = useState("");
    let { source,purchaseOrderId, orderId } = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [tonnage,setTonnage] = useState(0)
    const [errorMsg, setErrorMsg] = useState(false);
    const [otpPage, setOtpPage] = useState(false);
    const [otp, setOtp] = useState("");
    const [showError, setShowError] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const navigate = useNavigate();
    const [podPages, setPodPages] = useState([])
    const [imageArray, setImageArray] = useState(new Array(5).fill(null))
    const [previewImage, setPreviewImage] = useState([])
    const getPresignedUrl = useVendorStore((state) => state.getPresignedUrl);
    const getPresignedUrlStatus = useVendorStore(
        (state) => state.getPresignedUrlStatus
    );
    const podPresignedUrl = useVendorStore((state) => state.podPresignedUrl);
    const podFilePath = useVendorStore((state) => state.podFilePath);
    const [numberOfPodPages, setNumberOfPodPages] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);
    const validatePodImage = useVendorStore((state) => state.validatePodImage)
    const validatePodImageResp = useVendorStore((state) => state.validatePodImageResp)
    const getSellerOrderDetails = useVendorStore((state) => state.getSellerOrderDetails)
    const sellerOrderDetailsStatus = useVendorStore((state) => state.sellerOrderDetailsStatus)
    const sellerOrderDetails = useVendorStore((state) => state.sellerOrderDetails)
    const validatePodImageStatus = useVendorStore((state) => state.validatePodImageStatus)
    const [podPopUp, setPodPopUp] = useState(null)
    const [currentPageNumber, setCurrentPageNumber] = useState(0)
    const [doneMsg, setDoneMsg] = useState(false);
    const [fileTypeErr, setFileTypeErr] = useState(false);
    const [bo, setBo] = useState("");
    const getBuyerOrderPodDetails = useVendorStore((state) => state.getBuyerOrderPodDetails)
    const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO)

    useEffect(() => {
        getPurchaseOrders({
            is_item_required: true,
            purchase_order_id: purchaseOrderId,
        },true);
        getSellerOrderDetails(orderId);
    }, []);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
            setPo(purchaseOrders[0]);
        }
        getBuyerOrderPodDetails({
            purchase_order_id: purchaseOrderId,
        })
        for (let i = 0; i < buyerOrdersLinkedtoPO.length; i++) {
            if (buyerOrdersLinkedtoPO[i].pod_order_id === parseInt(orderId)) {
                setBo(buyerOrdersLinkedtoPO[i])
            }
        }
    }, [purchaseOrders]);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_BEGIN) {
            setShowLoader(true);
        }
        if (getPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
        }
    }, [getPurchaseOrderStatus]);

    useEffect(() => {
        if (po.purchase_order_items) {
            setItems(po.purchase_order_items);
        }
        if (bo.number_of_pod_pages === 0) {
            setOtpPage(true);
        } else if (bo.number_of_pod_pages) {
            setNumberOfPodPages(bo.number_of_pod_pages)
        }
    }, [po]);

    useEffect(() => {
        setImageArray(new Array(numberOfPodPages).fill(null))
    }, [numberOfPodPages])

    useEffect(()=>{
        calculateTonnage();
    },[items])

    useEffect(() => {
        if (sellerOrderDetailsStatus == STATUS_DONE) {
            if (sellerOrderDetails.pod_images) {
                let imgArray = sellerOrderDetails.pod_images.map((img) => {
                    if (img.page_number !== 0) {
                        return {
                            "page_no": img.page_number,
                            "img_url": img.image_url
                        };
                    }
                    return undefined;
                }).filter(Boolean)
                setPreviewImage(imgArray);
            }
        }
        setShowLoader(false)
    }, [sellerOrderDetails])

    useEffect(() => {
        if (validatePodImageStatus ==STATUS_DONE) {
            if (!validatePodImageResp.is_image_valid) {
                setPodPopUp(validatePodImageResp.popup)
            } else {
                getSellerOrderDetails(orderId);
            }
        }
    }, [validatePodImageStatus])

    useEffect(() => {
        if (podPopUp) {
            setShowPopUp(true);
        }
        setShowLoader(false);
    }, [podPopUp])

    useEffect(() => {
        if (sellerOrderDetails.all_pages_uploaded) {
            setDoneMsg(true);
        }
    }, [sellerOrderDetails])

    const calculateTonnage =()=> {
        let total = 0;
        for(const item of items){
            total=total+ item.quantity_ordered* item.weight_per_packet
        }
        setTonnage(total)
    }

    const navigateToOtpPage = () => {
        if (!sellerOrderDetails.all_pages_uploaded || errorMsg) {
            setErrorMsg(true);
        } else {
            setOtpPage(true);
        }
    }

    const makeValidateOtp = () => {
        validatePodOtp({
            order_id: parseInt(orderId),
            otp: otp,
            validate_all_pod_pages_uploaded: true,
            purchase_order_id: parseInt(purchaseOrderId)
        })
    }

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        navigate("/ddPo/all");
        window.location.reload();
    };

    const imageValidationPopUpCloseHandler = () => {
        setShowPopUp(false);
    };

    const uploadFileToS3 = (file, preSignedUrl) => {
        return fetch(preSignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file?.type,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    setErrorMsg(true);
                }
            }).catch((err) => {
                setErrorMsg(true);
            })
    }

    useEffect(()=>{
        if (validatePodOtpStatus === STATUS_DONE) {
            setOpenConfirmation(true);
        } else if (validatePodOtpStatus === STATUS_FAIL) {
            setShowError(true);
        }
    },[validatePodOtpStatus])

    useEffect(() => {
        if (getPresignedUrlStatus === STATUS_DONE) {
            uploadFileToS3(podPages[podPages?.length - 1], podPresignedUrl)
                .then((data) => {
                    validatePodImage({
                        "order_id": parseInt(orderId),
                        "image_url": podPresignedUrl,
                        "file_path": podFilePath,
                        "page_number": currentPageNumber
                    })
                })
        }
    }, [getPresignedUrlStatus]);

    useEffect(() => {
        if (validatePodImageStatus === STATUS_FAIL) {
            setErrorMsg(true);
            setShowLoader(false);
        }
    }, [validatePodImageStatus]);

    const handleImageInputChange = (e, pageNo) => {
        setCurrentPageNumber(pageNo);
        const file = e.target.files[0];
        if (file && WhiltelistedFileTypes.WhiltelistedFileTypes(file.type)) {
            setErrorMsg(false);
            setShowLoader(true);
            setFileTypeErr(false);
            const reader = new FileReader();
            setPodPages([...podPages, file]);
            getPresignedUrl({
                order_id: parseInt(orderId),
            });
            reader.readAsDataURL(file);
        } else {
            setFileTypeErr(true);
        }
    };

    return (
        <React.Fragment>
            {showLoader ? (
                <Loader />
            ) : (
                <Wrapper>
                    <DDPoHeader
                        poId={po.id}
                        purchaseOrderNumber={po.purchase_order_number}
                        itemsLength={items.length}
                        tonnage={tonnage}
                        source="podForm"
                    ></DDPoHeader>
                    {otpPage ? <div>
                            <div className="d-flex flex-column po-content">
                                <WhiteWrapper className="invoice-form ml-2 mr-2 mt-1">
                                    <div className="d-flex flex-column mr-4 ml-1">
                                        <span className="fs-18 fw-700">Enter verification code</span>
                                        <span className="fs-12 fw-400 text-gray-cool mt-1 mb-4">
                                        shared by customer during delivery
                                    </span>
                                    </div>
                                    <OtpInput
                                        inputType="number"
                                        containerStyle={`${styles.otpInputField} mb-3`}
                                        value={otp}
                                        onChange={(val) => {
                                            setOtp(val);
                                        }}
                                        numInputs={6}
                                        renderSeperator={"-"}
                                        renderInput={(a) => <input {...a} />}
                                    />
                                    {showError ? (
                                        <errorDisp className="fs-12 text-red-700">
                                            Wrong OTP Entered
                                        </errorDisp>
                                    ) : (
                                        <></>
                                    )}
                                </WhiteWrapper>
                            </div>
                            <WhiteWrapper className="po-bottom">
                                <Button
                                    className="fs-13 text-white fw-600"
                                    onClick={makeValidateOtp}
                                >
                                    Mark delivered
                                </Button>
                            </WhiteWrapper>
                            <SushiModal visible={openConfirmation} isCentered>
                                <FlexColumn className="align-items-center p-5">
                                    <img src="/logos/tick_logo.svg" height="80px" width="80px" />
                                    <span className="fs-22 fw-700 my-4">Order delivered</span>
                                    <span
                                        className="fs-14 fw-500 text-red-500"
                                        onClick={closeConfirmation}
                                    >{" "}
                                        Okay
                                </span>
                                </FlexColumn>
                            </SushiModal>
                        </div> :
                        <div>
                            <div className="d-flex flex-column po-content">
                                <WhiteWrapper className="invoice-form ml-2 mr-2 mt-1">
                                    <div className="d-flex flex-column mr-4 ml-1">
                                        <span className="fs-18 fw-700">Upload hyperpure delivery challan</span>
                                        <span className="fs-12 fw-400 text-gray-cool mt-1">
                                            with signature and issue
                                        </span>
                                        <span className="fs-9 fw-400 text-gray-cool mt-1">(supported file types are .jpeg, .jpg, .png and .tiff)</span>
                                    </div>
                                    <SeparatorLine />
                                    <div className="d-flex flex-wrap">
                                        {imageArray.map((box, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="box ml-2 mt-1">
                                                        {previewImage && previewImage.length > index ? (
                                                            <div>
                                                                <img
                                                                    className="image-preview-1"
                                                                    src={previewImage[index]?.img_url}
                                                                    alt={`Image-${index + 1}`}
                                                                    id={`Image-icon-${index}`}
                                                                />
                                                                <div className="fs-10 fw-400 text-red-500">{`Page ${previewImage[index]?.page_no}`}</div>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex flex-column">
                                                                <input src={"/logos/camera_add.svg"} type="file"
                                                                       accept="image/*" onChange={(e) => handleImageInputChange(e, index+1)}
                                                                       style={{display: "none"}}
                                                                       id={`imageInput-${index}`}/>
                                                                <label htmlFor={`imageInput-${index}`}>
                                                                    <img
                                                                        className="icon"
                                                                        src={"/logos/camera_add.svg"}
                                                                        width="25px"
                                                                        height="25px"
                                                                        alt="Upload Image"
                                                                        id={`Image-icon-${index}`}
                                                                    />
                                                                </label>
                                                                <span className="fs-10 fw-400 text-red-500">{`Page ${index + 1}`}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {errorMsg ? (
                                        <errorDisp className="fs-12 text-red-700">
                                            Please add delivery challan
                                        </errorDisp>
                                    ) : doneMsg ? (
                                        <errorDisp className="d-flex mt-2">
                                            <img src={greenCheck} className="mr-1"/>
                                            <span className="mt-1 fs-12 text-green-700">All pages uploaded</span>
                                        </errorDisp>
                                    ) : fileTypeErr ?
                                        <errorDisp className="fs-12 text-red-700">
                                            Please upload images with correct file type
                                        </errorDisp>
                                       : (
                                        <></>
                                    )}
                                </WhiteWrapper>
                            </div>
                            <WhiteWrapper className="po-bottom">
                                <Button
                                    className="fs-13 text-white fw-600"
                                    onClick={navigateToOtpPage}
                                >
                                    Verify with OTP
                                </Button>
                            </WhiteWrapper>
                            <SushiModal visible={showPopUp} isCentered>
                                <FlexColumn className="align-items-center p-5">
                                    <div className="align-self-end" onClick={imageValidationPopUpCloseHandler} > <Cross /> </div>
                                    <img src={podPopUp?.image_link} height="80px" width="80px" />
                                    <span className="fs-22 fw-700 my-4">{podPopUp?.title}</span>
                                    <span className="fs-12 mb-4">{podPopUp?.content}</span>
                                    <div className="invalid-image-box">
                                        <div className="d-flex justify-content-between">
                                            {podPopUp?.negative_tags?.map((tag) => {
                                                return (
                                                    <span className="d-flex justify-content-between fs-15">
                                                        <img src={redCross}/>
                                                        <span className={"mt-1"}>{tag}</span>
                                                    </span>
                                                )
                                            })}
                                            {podPopUp?.positive_tags?.map((tag) => {
                                                return (
                                                    <span className="d-flex justify-content-between fs-15">
                                                        <img src={greenCheck}/>
                                                        <span className={"mt-1"}>{tag}</span>
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <span
                                        className="fs-14 fw-500 text-red-500 mt-4"
                                        onClick={imageValidationPopUpCloseHandler}
                                    >
                                        Retake photo
                                    </span>
                                </FlexColumn>
                            </SushiModal>
                        </div>}
                </Wrapper>
            )}
        </React.Fragment>
    );
};

export default DDPodFormV2;
