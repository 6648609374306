import React from "react";
import {
    Wrapper,
} from "components/core/Header/styles.header";
import BackButton from "../Button/BackButton";
import {useNavigate} from "react-router-dom";
import { order } from "styled-system";
import { MandiTag } from "../GRN/styles.grn";
import { PO_ORDER_TAG_MANDI_DD } from "utils/constants/others";

const DDPoHeader = (props) => {
    const {
        poId,
        purchaseOrderNumber,
        itemsLength,
        tonnage,
        source,
        orderTag
    } = props;

    const navigate = useNavigate();
    const handleBackClick = (source) => {
        if (source === "form") {
            navigate(`/ddPo/details/all/${poId}`);
        } else {
            navigate(`/ddPo/all`);
            window.location.reload();
        }
    }
    return (
        <Wrapper className="po-header">
                <div className="d-flex ml-4 mt-4 flex-row">
                    {source === "reuploadInvoice" ?  <></> : <span className="mt-4" onClick={() => handleBackClick(source)}> <BackButton /> </span>}
                    <div className="d-flex flex-column mr-4 ml-1">
                        <span className="fs-18 fw-700">{purchaseOrderNumber}</span>
                        <span className="fs-12 fw-400 text-gray-cool mt-1">
                            {itemsLength} product{itemsLength > 1 ? "s" : ""}
                            {" "}•{" "}{tonnage>999 ? (tonnage/1000).toFixed(2) : tonnage.toFixed(2)} {tonnage>999 ? "tonne": "kg"}
                        </span>
                    </div>
                    <div>
                        {orderTag === PO_ORDER_TAG_MANDI_DD ? (<MandiTag><span className="fs-12 fw-300">MANDI</span></MandiTag>) : null}
                    </div>
                </div>
        </Wrapper>
    );
};

export default DDPoHeader;
