import { create } from "zustand";
import { api } from "network/network";
import {APIendpoints, DefaultParams } from "utils/constants/APIendpoints";
import { devtools } from "zustand/middleware";
import {API_HOST_POD, API_HOST_WMS} from "config/api.config";
import { makeEncodedQueryParams } from "operations/operations";
import _get from "lodash-es/get";
import { STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";

let useVendorStore = create(devtools((set,get)=>({
    getPurchaseOrderStatus:"",
    purchaseOrders:[],
    poCount:0,

    getPoGrnMappingStatus:"",
    poGrnList:null,
    grnCount:0,

    updateSellerApprovalStatus:"",

    error:null,
    sellerDetails: {},
    updatePurchaseOrderStatus:"",
    podPresignedUrl: "",
    getPresignedUrlStatus:"",
    validatePodOtpStatus: "",
    podFilePath: "",
    uploadToPresignedUrlStatus: "",
    validatePodImageStatus: "",
    uploadPodStatus: "",
    updatePurchaseOrderError: "",
    updatePurchaseOrderErrorModalData: {},
    validatePodImageResp: null,
    sellerOrderDetailsStatus: "",
    sellerOrderDetails: {},
    createAndGetCnStatus:"",
    createAndGetCnError:"",
    markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:"",
    markBuyerOrderCompletedLinkedToDDPurchaseOrderError:"",
    markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:"",
    markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderError:"",

    getBuyerOrderPodDetailsStatus : "",
    buyerOrdersLinkedtoPO : [],
    grnDocumentUpdateData: {},
    grnDocumentUpdateDataError : "",
    updateGrnDocumentUpdateDataStatus: "",
    getGrnDocumentUpdateDataStatus : "",
    updateGrnDocumentUpdateDataError : "",
    getHomePagePopUpStatus: "",
    homePagePopUp: {},
    updateHomePagePopUpStatus: "",


    getPurchaseOrders: async (params,isFirstPage,successCallback,source) =>  {
        set({getPurchaseOrderStatus:STATUS_BEGIN})
        const queryParams = DefaultParams.getPurchaseOrder
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.getPurchaseOrderList}${queryString}`
        api.get(API_HOST_WMS,url)
        .then((response)=>{
            if (typeof successCallback === 'function') {
                successCallback(_get(response,["data","response"], {}), source)
                return
            }
            if(isFirstPage){
                set({purchaseOrders:_get(response,["data","response","purchase_orders"],{})})
            }
            else{
                const newPurchaseOrders= _get(response,["data","response","purchase_orders"],{})
                set(state => ({purchaseOrders:[...state.purchaseOrders,...newPurchaseOrders]}))
            }
            set({poCount:(_get(response,["data","response","order_count"],{}))});
            set({getPurchaseOrderStatus:STATUS_DONE});
        })

        .catch((error)=> {set({
            error:(_get(error,["data", "response", "error"],{}))
            });
            set({getPurchaseOrderStatus:STATUS_FAIL});
        })
    },

    getBuyerOrderPodDetails: async (params) => {
        set({getBuyerOrderPodDetailsStatus:STATUS_BEGIN})
        const queryParams = DefaultParams.getBuyerOrderPodDetails
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.getBuyerOrderPodDetails}${queryString}`

        api.get(API_HOST_WMS,url)
            .then((data) => {
                set({buyerOrdersLinkedtoPO:_get(data, ["data","response","buyer_orders"],{})})

                set({getBuyerOrderPodDetailsStatus:STATUS_DONE});
            })
            .catch((error)=>{set({
                    error: (_get(error,["response","error"],{}))
                }); 
                set({getBuyerOrderPodDetailsStatus:STATUS_FAIL});
            });
    },

    getPoGrnMappings: async (params,isFirstPage) => {
        set({getPoGrnMappingStatus:STATUS_BEGIN})
        const queryParams = DefaultParams.getPoGrnMappings
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.getPoGrnMappings}${queryString}`
        api.get(API_HOST_WMS,url)
        .then((response)=>{
            if(isFirstPage){
                set({poGrnList:(_get(response,["data","response","po_grn_mappings"],{}))})
            }
            else{
                const newPoGrnMappings= _get(response,["data","response","po_grn_mappings"],{})
                set(state => ({poGrnList:[...state.poGrnList,...newPoGrnMappings]}))
            }
            set({grnCount:(_get(response,["data","response","total_count"],{}))});
            set({getPoGrnMappingStatus:STATUS_DONE});
        })

        .catch((error)=>{set({
                error: (_get(error,["response","data","error"],{}))
            });
            set({getPoGrnMappingStatus:STATUS_FAIL});
        })
    },

    updateSellerStatus: async (params, successCallback) => {
        set({updateSellerApprovalStatus:STATUS_BEGIN})
        const queryParams = DefaultParams.updateSellerStatus
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.updateSellerStatus}${queryString}`
        api.put(API_HOST_WMS,url)
        .then( (data) => {
            set({updateSellerApprovalStatus:STATUS_DONE})
            if (successCallback) {
                successCallback()
            }
        })
        .catch((error)=>{set({
            error: (_get(error,["response","data","error"],{}))
        });
        set({updateSellerApprovalStatus:STATUS_FAIL});
        })
    },

    updateEntityStatus:  (entity, entityStatus) => {
        set({[entity]:entityStatus})
    } ,

    getSellerDetails: async (params) => {
        const queryParams = DefaultParams.getSellerDetails
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.getSellerDetails}${queryString}`
        api.get(API_HOST_WMS,url)
            .then((data) => {
                set({sellerDetails:_get(data, ["data","response"])})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
            })
    },

    updatePurchaseOrder: async (requestBody, successCallback, failureCallback) => {
        set({updatePurchaseOrderStatus:STATUS_BEGIN})
        const url = `${APIendpoints.updatePurchaseOrder}`
        api.put(API_HOST_WMS,url,requestBody)
            .then((data) => {
                set({updatePurchaseOrderStatus:STATUS_DONE})
                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error)=>{
                set({updatePurchaseOrderError:_get(error,["response","data", "error", "message"],{})})
                let errorData = _get(error,["response","data", "error", "data"], "{}")
                set({updatePurchaseOrderErrorModalData: JSON.parse(errorData)})
                set({updatePurchaseOrderStatus:STATUS_FAIL});
                if (failureCallback){
                    failureCallback();
                }
            });
    },
    
    markCompleteBuyerOrderLinkedToDDPurchaseOrder: async (requestBody) => {
        set({markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_BEGIN})
        const url = `${APIendpoints.markBuyerOrderCompletedLinkedToDDPurchaseOrder}`
        api.put(API_HOST_WMS,url,requestBody)
            .then((data) => {
                set({markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_DONE})
            })
            .catch((error)=>{
                set({markBuyerOrderCompletedLinkedToDDPurchaseOrderError:_get(error,["response","data", "error", "message"],{})})
                set({markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_FAIL});
            });
    },

    markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder: async (requestBody) => {
        set({markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_BEGIN})
        const url = `${APIendpoints.markBulkBuyerOrderCompletedLinkedToMandiDDPurchaseOrder}`
        api.put(API_HOST_WMS,url,requestBody)
            .then((data) => {
                set({markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_DONE})
            })
            .catch((error)=>{
                set({markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderError:_get(error,["response","data", "error", "message"],{})})
                set({markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus:STATUS_FAIL});
            });
    },

    createAndGetCnData: async (requestBody) => {
        set({createAndGetCnStatus:STATUS_BEGIN})
        const url = `${APIendpoints.createAndGetCnData}`
        api.post(API_HOST_WMS,url,requestBody)
            .then((data) => {
                set({CnData:_get(data, ["data","response"])})
                set({createAndGetCnStatus:STATUS_DONE})
            })
            .catch((error)=>{
                set({createAndGetCnError:_get(error,["response","error"],{})})
                set({createAndGetCnStatus:STATUS_FAIL});
            });
    },

    getPresignedUrl: async (requestBody) => {
        set({getPresignedUrlStatus:STATUS_BEGIN})
        const url = `${APIendpoints.getPresignedUrl}`
        api.post(API_HOST_POD,url,requestBody)
            .then((data) => {
                set({podPresignedUrl:_get(data, ["data","response", "presigned_url"])})
                set({podFilePath:_get(data, ["data","response", "file_path"])})
                set({getPresignedUrlStatus:STATUS_DONE})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
                set({getPresignedUrlStatus:STATUS_FAIL});
            })
    },

    validatePodOtp: async (requestBody) => {
        set({validatePodOtpStatus:STATUS_BEGIN})
        const url = `${APIendpoints.validatePodOtp}`
        api.put(API_HOST_POD,url,requestBody)
            .then((data) => {
                set({validatePodOtpStatus:STATUS_DONE})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
                set({validatePodOtpStatus:STATUS_FAIL});
            })
    },

    validatePodImage: async (requestBody, uploadPod, formData) => {
        set({validatePodImageStatus:STATUS_BEGIN})
        const url = `${APIendpoints.validatePodImage}`
        api.post(API_HOST_POD,url,requestBody)
            .then((data) => {
                set({validatePodImageResp:_get(data,["data","response"],{})})
                set({validatePodImageStatus:STATUS_DONE})
            })
            .catch((error)=>{
                set({
                error: (_get(error,["response","error"],{}))
            });
                set({validatePodImageStatus:STATUS_FAIL});
            })
    },

    uploadPod: async (requestBody) => {
        set({uploadPodStatus:STATUS_BEGIN})
        const url = `${APIendpoints.uploadPodImage}`
        api.post(API_HOST_POD,url,requestBody)
            .then((data) => {
                set({uploadPodStatus:STATUS_DONE})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
                set({uploadPodStatus:STATUS_FAIL});
            })
    },

    getSellerOrderDetails: async (orderId) => {
        set({sellerOrderDetailsStatus:STATUS_BEGIN})
        const url = `${APIendpoints.sellerOrderDetails(orderId)}`
        api.get(API_HOST_POD,url)
            .then((data) => {
                set({sellerOrderDetailsStatus:STATUS_DONE})
                set({sellerOrderDetails: _get(data,["data","response"],{})})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
                set({sellerOrderDetailsStatus:STATUS_FAIL});
            })
    },

    getGrnDocumentUpdateData: async (params) => {
        const queryParams = DefaultParams.getGrnDocumentUpdateData
        let queryString = makeEncodedQueryParams(queryParams,params)
        const url = `${APIendpoints.grnDocumentUpdateData}${queryString}`
        api.get(API_HOST_WMS,url)
            .then((data) => {
                set({getGrnDocumentUpdateDataStatus:STATUS_DONE})
                set({grnDocumentUpdateData: _get(data,["data","response"],{})})
            })
            .catch((error)=>{
                set({getGrnDocumentUpdateDataStatus:STATUS_FAIL})
                set({grnDocumentUpdateDataError:_get(error,["response","data", "error", "message"],{})})
            })
    },

    updateGrnDocumentUpdateData: async (requestBody) => {
        const url = `${APIendpoints.grnDocumentUpdateData}`
        api.put(API_HOST_WMS,url,requestBody)
            .then((data) => {
                set({updateGrnDocumentUpdateDataStatus:STATUS_DONE})
            })
            .catch((error)=>{set({
                error: (_get(error,["response","error"],{}))
            });
            set({updateGrnDocumentUpdateDataStatus:STATUS_FAIL})
            set({updateGrnDocumentUpdateDataError:_get(error,["response","data", "error", "message"],{})})
            })
    },

    updateHomePagePopUp: async (requestBody) => {
        const url = `${APIendpoints.homePagePopUp}`;
        api
          .put(API_HOST_WMS, url, requestBody)
          .then((data) => {
            set({ updateHomePagePopUpStatus: STATUS_DONE });
          })
          .catch((error) => {
            set({
              error: _get(error, ["response", "error"], {}),
            });
            set({ updateHomePagePopUpStatus: STATUS_FAIL });
          });
      },
  
      getHomePagePopUp: async (params) => {
        let queryString = makeEncodedQueryParams(DefaultParams.getHomePagePopUpParams, params);
        const url = `${APIendpoints.homePagePopUp}${queryString}`;
        api
          .get(API_HOST_WMS, url)
          .then((data) => {
            set({ homePagePopUp: _get(data, ["data", "response"], {}) });
          })
          .catch((error) => {
            set({
              error: _get(error, ["response", "error"], {}),
            });
          });
      },
})));

export default useVendorStore;