import styled from "styled-components";

export const List = styled.div`
  height: 94vh;
  background: #f8f8fb;
  overflow-y: scroll;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 1px solid #f8f8fb;
  border-radius: 50%;
`;

export const FilterBox = styled.div`
  display: flex;
  min-width: max-content;
  background-color: ${(props) => (props.clicked ? "#FFF5F6;" : "white")};
  border: ${(props) => (props.clicked ? "1px solid #EF4F5F" : "none")};
  box-shadow: 0px 4px 20px rgba(28, 28, 28, 0.04);
  border-radius: 8px;
  margin-right: 1rem;
  padding: 10px;
  justify-content: center;
`;
