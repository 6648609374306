import React, { useEffect, useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { Button, Card, SeparatorLine } from "../Common/styles.common";
import { Circle } from "./styles.po";
import { STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";
import { Loader } from "../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ShowToast from "../Common/Toast";

const BoCard = (props) => {

    const bo = props.bo;
    const po = props.po;
    const getSellerOrderDetails = useVendorStore((state) => state.getSellerOrderDetails)
    const sellerOrderDetails = useVendorStore((state) => state.sellerOrderDetails)
    const sellerOrderDetailsStatus = useVendorStore((state) => state.sellerOrderDetailsStatus)
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [currentbo, setCurrentBo] = useState("");





    const checkIfPodvalidationEnabled = (sellerOrderDetails) => {
      if (!sellerOrderDetails || !sellerOrderDetails.is_pod_image_validation_enabled) {
        return false
      }
      if (!sellerOrderDetails.pod_images) {
        return true
      }
      let pageNumbers = sellerOrderDetails.pod_images?.map((img) => {
        if (img.page_number <= 0) {
          return -1;
        }
        return 1;
      })
      return pageNumbers.includes(1);
    }


    const navigateToPodForm = () => {
      setCurrentBo(bo)
      getSellerOrderDetails(bo?.pod_order_id)
    }

    useEffect(() => {
      
      if (sellerOrderDetailsStatus === STATUS_DONE && currentbo.pod_order_id === bo.pod_order_id) {
        let redirectToV2 = checkIfPodvalidationEnabled(sellerOrderDetails);
        if (redirectToV2) {
          navigate(`/ddPo/pod/v2/${po.id}/${bo.pod_order_id}`)
        } else {
          let status = "dc"
          if (sellerOrderDetails && sellerOrderDetails.pod_images) {
            let pageNumbers = sellerOrderDetails.pod_images?.map((img) => {
              if (img.page_number <= 0) {
                return -1;
              }
              return 1;
            })
            if (pageNumbers) {
              status = "otp"
            }
          }
          navigate(`/ddPo/pod/${po.id}/${bo.pod_order_id}/${status}`)
        }
        setShowLoader(false);
      } else if (sellerOrderDetailsStatus === STATUS_FAIL) {
        setErrorMsg("Error getting seller order details")
        setShowToast(true);
      }
    }, [sellerOrderDetailsStatus])


    return ( 
        <React.Fragment>
            {showLoader ? (
        <Loader />
      ) : (
                <Card onClick={navigateToPodForm} className="mt-4 mx-4">
                    <div className="px-4">
                        <div className="d-flex">
                            <Circle className="mr-3">
                                {bo.is_pod_validated  && bo.is_otp_verified ? ( <img src="../../logos/tick.svg" height="16px" width="15px" />):( <img src="../../logos/bang.svg" height="16px" width="15px" />)}
                            </Circle>
                            <div className="d-flex flex-column">
                                <span className="fw-700 fs-18">{bo.buyer_order_number}</span>
                                <span className="fs-10 pt-1">
                                  {bo.is_pod_validated && bo.is_otp_verified ? (<span className="fs-12 text-green">Delivery challan uploaded & otp verified successfully</span>):
                                  bo.is_pod_validated && !bo.is_otp_verified ? (<span className="fs-12 text-red">Otp not verified yet</span>)
                                  :(<span className="fs-12 text-red">Delivery challan not uploaded yet</span>)}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center pt-3">
                            <Circle className="mr-3">
                                <img src="../../logos/location_logo.svg" height="16px" width="15px" />
                            </Circle>
                            <div className="">
                                <span className="fs-14 fw-400 text-gray-cool">{bo.buyer_order_outlet_name}</span>
                            </div>
                        </div>
                    </div>
                </Card>
      )}
      {showToast?
        <ShowToast
        errorMsg={errorMsg}
        />
        
        :
        <></>
        }
        </React.Fragment>
    )
}

export default BoCard;