import React, { useEffect } from "react";
import useAuthStore from "components/core/Auth/useAuthStore";
import useAuth from "components/core/Auth/useAuth.hooks";
import { useNavigate , useLocation } from "react-router-dom";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = window.localStorage.getItem("token");
  const redirectPath = location?.pathname || "/";

  useEffect(() => {
    if (!token) navigate({ pathname: "/login" , search: `?redirect=${redirectPath}` });
  }, []);

  if (!!token) {
    return <>{children}</>;
  }
};

export default Auth;
