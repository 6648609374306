export const APIendpoints = {
  //Po-Grn Related APIs
  sendOtp: `/api/seller/login/otp`,
  validateOtp: `/api/seller/login/validateOTP`,
  getPurchaseOrderList: `public/api/seller_hub/wms/purchase_order_list`,
  getPoGrnMappings: `public/api/seller_hub/wms/po-grn-mappings`,
  updateSellerStatus: `public/api/seller_hub/wms/update_seller_status`,
  getSellerDetails: `public/api/seller_hub/wms/get_seller_details`,
  updatePurchaseOrder: `/public/api/seller_hub/purchase_order`,
  getPresignedUrl: `/api/seller/create_presigned_url`,
  validatePodOtp: `/api/seller/validate_otp`,
  validatePodImage: `/api/seller/validate_image`,
  uploadPodImage: `/api/seller/pod`,
  sellerOrderDetails: (orderId) => `api/seller/orders/${orderId}`,
  createAndGetCnData: `public/api/seller_hub/create_and_get_cn_data`,
  markBuyerOrderCompletedLinkedToDDPurchaseOrder: `/public/api/seller_hub/complete_buyer_order`,
  getBuyerOrderPodDetails: `public/api/seller_hub/wms/buyer_order_pod_details`,
  markBulkBuyerOrderCompletedLinkedToMandiDDPurchaseOrder:
    "/public/api/seller_hub/wms/bulk_complete_buyer_orders",
  grnDocumentUpdateData: `/public/api/seller_hub/grn_document_update`,
  homePagePopUp: `/public/api/seller_hub/wms/home_page_popup`,
  //forGeneric APIs
  uploadDocument: `/public/api/seller_hub/document`,
};

export const DefaultParams = {
  getPurchaseOrder: {
    purchase_order_id: "",
    purchase_order_number: "",
    vendor_id: "",
    page_size: 20,
    page_no: 1,
    warehouse_code: "",
    created_at: "",
    expected_delivery_date: "",
    po_status: "",
    grn_number: "",
    is_item_required: false,
    is_payment_details_required: "",
    is_history_required: "",
    payment_status: "",
    payment_number: "",
    po_consideration_dt: "",
    outlet_name: "",
    created_at_dt_rng: "",
    vendor_account_id: "",
    po_amt_range: "",
    order_tag: "",
    invoice_mismatch: "",
    is_organization_details_required: false,
    created_by_user_ids: [],
    is_po_grn_items_required: false,
    is_po_grn_mapping_required: false,
    seller_status: "",
    fetch_po_with_no_grns: false,
    fetch_invoice: false,
    fetch_po_with_no_seller_invoice: false,
    is_delivery_details_required: false,
    seller_action_status: "",
    order_status: "",
    order_tags: "",
  },

  getPoGrnMappings: {
    purchase_order_id: 0,
    purchase_order_number: "",
    warehouse_code: "",
    po_grn_mapping_id: 0,
    page_number: 1,
    page_size: 20,
    is_po_grn_items_required: false,
    grn_status: "",
    grn_statuses: [],
    warehouse_codes: "",
    payment_status: "",
    outlet_name: "",
    outlet_id: 0,
    account_id: 0,
    is_invoice_generation_disabled: false,
    tnc_accepted: false,
    seller_status: "",
    is_payment_details_required: false,
    is_seller_status_history_required: false,
    get_cn_data: false,
  },

  updateSellerStatus: {
    entity_id: 0,
    entity_type: "",
    seller_status: "",
    supplier_bill: "",
    account_id: 0,
    tnc_accepted_time: "",
  },

  getSellerDetails: {
    phone_number: "",
    check_for_dn_tncs: false,
  },

  updateSellerInvoice: {
    invoice_number: "",
    invoice_date: "",
    invoice_amount: 0,
    order_bill_image: "",
    dd_order_details: "",
    pod_order_id: 0,
    number_of_pod_pages: 0,
    order_id: 0,
    page_number: 0,
    file_path: "",
  },

  getBuyerOrderPodDetails: {
    purchase_order_id: 0,
  },

  getGrnDocumentUpdateData: {
    grn_number: "",
    reference_number: "",
    get_presigned_url: false,
    source: "",
  },

  updateGrnDocumentUpdateData: {
    reference_number: "",
    grn_number: "",
    invoice_number: "",
    invoice_amount: 0,
    document_path: "",
    status: "",
  },

  getHomePagePopUpParams: {
    seller_outlet_id: "",
  },
};
