import styled from "styled-components";
import { space, typography } from "styled-system";

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const Title = styled.h1`
  font-size: 1em;
  display: flex;
  color: ${(props) => props.theme.colors.default};
`;

export const Logo = styled.h2`
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${space}
`;

// Create a <Wrapper> react component that renders a <section> with
// some padding and a papayawhip background
export const Wrapper = styled.section`
  padding: 0em 1em 0em 1em;
  background: white;
`;

export const NewTitle = styled(Title)`
  font-size: 2em;
  color: ${(props) => props.theme.colors.blue};
  ${space}
  ${typography}
`;
