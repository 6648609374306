const baseTheme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1441px", "1600px"],
  spaces: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512, 1024],
  fontSizes: [10, 12, 14, 16, 18, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    normal: 1.3,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  borders: [
    0,
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  radii: [0, 2, 4, 16, 9999, "100%"],
  widths: [16, 24, 32, 64, 128, 256],
  heights: [16, 24, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    black: "#262833",
    white: "#fff",
    blue: "#00Adff",
    green: "#4ACFAC",
    orange: "#FFA48E",
    coral: "#5D5F71",
    red: "#E65F5C",
    subtleGray: "#ededed",
    secondary_variant: "#696969",
    default: "#0A2E36",
    primary: "#E65F5C",
    secondary: "#FFA48E",
  },
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
  maxLayoutWidth: 128 * 5 + parseFloat(900),
  sizes: ["320px", "480px", "768px"],
};

export default baseTheme;
