import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 1em;
  background: #f8f8fb;
  height:100vh;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${props=> props.bg? props.bg : ""};
`;

export const SubBox = styled(Box)`
    background: white;
    border-radius: 0 0 20px 20px;
    padding-top: 16px;
    padding-bottom: 16px;
`;

export const OutletBox = styled(Box)`
    display: flex;  
    background: white;
    border-radius: 16; 
    border: '0.50px #F5F5F5 solid';
    width: 20em;
    height: 2em;
    margin-top: 5px;
    margin-bottom: 5px;
`;


export const LargeBox = styled(Box)`
  background: white;
  height: 180px;
  width: 47%;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(28, 28, 28, 0.04);
  padding:10px;
`;

export const AllPoButton = styled.button`
  background: white;
  height: 70px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 4px 20px rgba(28, 28, 28, 0.04);
  margin-bottom: 10px;
`;

export const Circle = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background-color: #ef4f5f;
  border-radius: 50%;
  position: relative;
  left: 30px;
  bottom: 75px;
  justify-content: center;
`;

export const CircleDDPo = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background-color: #ef4f5f;
  border-radius: 50%;
  position: relative;
  left: -15px;
  bottom: 6px;
  justify-content: center;
`;
