import React, { useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import PoCard from "./PoCard";
import { useEffect } from "react";
import { List } from "./styles.po";
import { Wrapper } from "../Header/styles.header";
import { strings } from "utils/constants/strings";
import { PO_STATUS_APPROVED, SELLER_STATUS_NON_APPROVED, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import BackButton from "../Button/BackButton";
import { Loader } from "../Common/Loader";
import ShowToast from "../Common/Toast";
import { BottomModal, Button, FlexColumn } from "../Common/styles.common";
import Cross from "sushiweb/Icons/all/Cross";


const PendingPo = () => {
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const poCount = useVendorStore((state)=>state.poCount)
  const outletId = window.localStorage.getItem("outlet_id");
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("")
  const [poCreator, setPoCreator] = useState("")
  const [openCallModal, setOpenCallModal] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    getPurchaseOrders({
      vendor_id: outletId,
      is_item_required: true,
      po_status: PO_STATUS_APPROVED,
      seller_status: SELLER_STATUS_NON_APPROVED,
      page_no:1,
      fetch_po_with_no_grns:true,
    },true);
  }, []);

  const loadNextPage = () => {
    getPurchaseOrders({
        vendor_id: outletId,
        is_item_required: true,
        po_status: PO_STATUS_APPROVED,
        seller_status: SELLER_STATUS_NON_APPROVED,
        page_no: pageNumber+1,
        fetch_po_with_no_grns:true,
        },false);
    setPageNumber(pageNumber+1)
  }


  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_BEGIN && pageNumber===1) {
      setShowLoader(true);
    }
    if (getPurchaseOrderStatus === STATUS_DONE) {
      setShowLoader(false);
    }
    if (getPurchaseOrderStatus === STATUS_FAIL) {
      setShowLoader(false);
      setShowToast(true);
    }
  }, [getPurchaseOrderStatus]);

  useEffect(()=>{
    if(poCount===0){
      navigate("/")
    }
  },[poCount])

  let cards = [];

  if (purchaseOrders && purchaseOrders.length) {
    for (let index = 0; index < purchaseOrders.length; index++) {
      cards.push(<PoCard source={"pending"} key={index} po={purchaseOrders[index]} openCallModal={openCallModal} setOpenCallModal={setOpenCallModal} setPhoneNumber={setPhoneNumber} setPoCreator={setPoCreator}/>);
    }
  }

  const handleCall = () => {
    const formattedPhoneNumber = phoneNumber.replace(/\s/g, ''); // Remove any whitespace from the phone number
    window.location.href = `tel:+91${formattedPhoneNumber}`;
  };


  return (
    <React.Fragment>
      {showLoader && pageNumber===1? (
        <Loader />
      ) : (
        <>
          <Wrapper>
            <div className="d-flex">
                <BackButton pathName={"/"} />
                <div className="fs-16 fw-600 mt-4 mb-4">
                {strings.pendingPoString}
                </div> 
            </div>
          </Wrapper>
          <InfiniteScroll
            className="infinite-scroll"
            height={"93%"}
            dataLength={cards.length}
            hasMore={cards.length < poCount}
            next={loadNextPage}
            loader={<Loader isScrollLoader={true}/>}
          >
            {cards}
          </InfiniteScroll>
        </>
      )}

      <BottomModal
        visible={openCallModal}
        onClose={()=>setOpenCallModal(false)}
        showCloseIcon={true}
      >
        <FlexColumn className="align-items-center p-4">
          <Cross color={"#696969"} className="close" onClick={()=>setOpenCallModal(false)}/>
          <img
            src={"../logos/po_creator_logo.svg"}
          />
          <span className="fs-20 fw-700 mt-4">{poCreator}</span>
          <span className="fs-14 fw-400 text-gray-cool mt-3 mb-4">PO creator</span>
          <Button onClick={handleCall}>
            <span className="fs-13 fw-500 text-white">Call</span>
          </Button>
        </FlexColumn>

      </BottomModal>

      {showToast?
        <ShowToast/>
        :
        <></>
      }
    </React.Fragment>
  );
};

export default PendingPo;
