import "./App.css";
import Header from "components/core/Header/Header";
import apiEndpoint from "config/api.config";
import Auth from "components/core/Auth/Auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "components/core/Route/PrivateRoute";
import Container from "components/core/Container/Container";
import Login from "components/core/Login/Login";
import HomePage from "components/core/HomePage/HomePage";
import PendingPo from "components/core/PO/PendingPo";
import PoDetails from "components/core/PO/PoDetails";
import PendingGrn from "components/core/GRN/PendingGrn";
import GrnDetails from "components/core/GRN/GrnDetails";
import AllPoView from "components/core/PO/AllPoView";
import Invoice from "components/core/GRN/Invoice";
import CreditNote from "components/core/GRN/CreditNote";
import Tnc from "components/core/Login/Tnc"
import AllDDPoView from "./components/core/PO/AllDDPoView";
import DDPoDetails from "./components/core/PO/DDPoDetails";
import DDPoInvoiceForm from "./components/core/PO/DDPoInvoiceForm";
import ToastContainer from "sushiweb/ToastContainer";
import DDPodForm from "./components/core/PO/DDPodForm";
import DDPodFormV2 from "./components/core/PO/DDPodFormV2";
import BOView from "./components/core/PO/BOView";
import ReuploadInvoice from "components/core/PO/ReuploadInvoice";

function App(props) {
  return (
    <ToastContainer>
    <div className="App">
      {/* Routes */}
      <Router>
        <Routes>
          <Route
            path={"/"}
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route path={"login"} element={<Login />} />
          <Route
            path={"po/pending"}
            element={
              <PrivateRoute>
                <PendingPo />
              </PrivateRoute>
            }
          />
          <Route
            path={"/tnc"}
            element={
                <PrivateRoute>
                    <Tnc />
                </PrivateRoute>
            }
          />
          <Route 
            path={"po/details/:source/:purchaseOrderId"} 
            element={
              <PrivateRoute>
                <PoDetails />
              </PrivateRoute>
            } 
          />
          <Route
            path={"ddPo/details/:source/:purchaseOrderId"}
            element={
                <PrivateRoute>
                    <DDPoDetails />
                </PrivateRoute>
            }
          />
          <Route
            path={"ddPo/invoice/:purchaseOrderId"}
            element={
                <PrivateRoute>
                    <DDPoInvoiceForm />
                </PrivateRoute>
            }
          />
          <Route
            path={"ddPo/pod/:purchaseOrderId/:orderId/:goTo"}
            element={
                <PrivateRoute>
                    <DDPodForm />
                </PrivateRoute>
            }
          />
          <Route
            path={"ddPo/pod/v2/:purchaseOrderId/:orderId"}
            element={
                <PrivateRoute>
                    <DDPodFormV2 />
                </PrivateRoute>
            }
          />
          <Route 
            path={"grn/pending"} 
            element={
              <PrivateRoute>
                <PendingGrn />
              </PrivateRoute>
            }  
          />
          <Route
            path={"grn/details/:source/:purchaseOrderId/:grnId"}
            element={
              <PrivateRoute>
                <GrnDetails />
              </PrivateRoute>
            }
          />
          <Route 
            path={"grn/invoice/:source/:purchaseOrderId/:grnId"} 
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          />
          {/* CreditNote */}
          <Route 
            path={"grn/creditNote/:grnId"} 
            element={
              <PrivateRoute>
                <CreditNote />
              </PrivateRoute>
            }
          /> 
          <Route 
            path={"po/all"} 
            element={
              <PrivateRoute>
                <AllPoView />
              </PrivateRoute>
            }
          />
          <Route
            path={"ddPo/all"}
            element={
                <PrivateRoute>
                    <AllDDPoView />
                </PrivateRoute>
            }
          />
          <Route
            path={"ddPo/:purchaseOrderId/buyer_orders"}
            element={
                <PrivateRoute>
                    <BOView />
                </PrivateRoute>
            }
          />
         <Route
            path={"grn-document-update/:grnNumber/:referenceNumber"}
            element={
                <PrivateRoute>
                    <ReuploadInvoice/>
                </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
    </ToastContainer>
  );
}

export default App;
