import axios from "axios";
import {
  UNAUTHENTICATED_ERROR_EVENT,
  UNAUTHORIZED_ERROR_EVENT,
} from "./constants";
import { v4 as uuidv4 } from "uuid";

export const axiosInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      config.headers["X-Trackingid"] = `${uuidv4()}`;
      config.headers["x-client"] = "seller";
      config.headers["Authorization"] = window.localStorage.getItem("token");

      return config;
    },
    function (error) {
      const isS3Url =
        error.config &&
        /(s3-|s3\.)?(.*)\.amazonaws\.com/.test(error.config.url);
      if (!isS3Url && [401, 403, 410].includes(error.response.status)) {
        alert("Session Expired! Please login again");

        // redirect to sign in page
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("outletId");
        window.localStorage.removeItem("outletName");
        if (window && window.logoutChannelMessageHandler) {
          window.logoutChannelMessageHandler.postMessage("true");
        }

        // window.location.href = "/signin";
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if ([401, 403, 410].includes(error.response.status)) {
        document.dispatchEvent(new CustomEvent(UNAUTHENTICATED_ERROR_EVENT));
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("outletId");
        window.localStorage.removeItem("outletName");
        if (window && window.logoutChannelMessageHandler) {
          window.logoutChannelMessageHandler.postMessage("true");
        }
        window.location.reload();
      } else if (error?.response?.status === 403) {
        document.dispatchEvent(new CustomEvent(UNAUTHORIZED_ERROR_EVENT));
      }
      return Promise.reject(error);
    }
  );
};
