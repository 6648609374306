import React, { useEffect, useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useParams } from "react-router-dom";
import InvoiceUploaded from "./InvoiceUploaded";
import DDPoInvoiceForm from "./DDPoInvoiceForm";
import { Loader } from "../Common/Loader";
import ShowToast from "../Common/Toast";
import { STATUS_FAIL,SOURCE_SELLER_HUB } from "../../../utils/constants/others";


const ReuploadInvoice = (props) => {
    let { grnNumber, referenceNumber } = useParams();
    const getGrnDocumentUpdateData = useVendorStore((state) => state.getGrnDocumentUpdateData);
    const grnDocumentUpdateData = useVendorStore((state) => state.grnDocumentUpdateData);
    const grnDocumentUpdateDataError = useVendorStore((state) => state.grnDocumentUpdateDataError);
    const getGrnDocumentUpdateDataStatus = useVendorStore((state) => state.getGrnDocumentUpdateDataStatus);
    const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
    const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
    const [status, setStatus] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [po, setPo] = useState({});
    const [items, setItems] = useState([]);
    const [tonnage, setTonnage] = useState(0);
    const [presignedUrl, setPresignedUrl] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    const [documentPath, setDocumentPath] = useState("");
    const [errorMsg, setErrorMsg] = useState({});
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        getGrnDocumentUpdateData({
            grn_number: grnNumber,
            reference_number: referenceNumber,
            get_presigned_url: true,
            source: SOURCE_SELLER_HUB
        });
    }, []);

    useEffect(() => {
        setStatus(grnDocumentUpdateData?.status);
        setPoNumber(grnDocumentUpdateData?.purchase_order_number);
        setPresignedUrl(grnDocumentUpdateData?.presigned_url);
        setDocumentPath(grnDocumentUpdateData?.document_path);
        setShowLoader(false);
    }, [grnDocumentUpdateData]);

    useEffect(() => {
        if (poNumber?.length > 0) {
        getPurchaseOrders({
            is_item_required: true,
            purchase_order_number: poNumber
        });
    }
    }, [poNumber])

    useEffect(() => {

        if (getGrnDocumentUpdateDataStatus === STATUS_FAIL) {
            setShowToast(true);
            setErrorMsg(grnDocumentUpdateDataError);
        }
    }, [getGrnDocumentUpdateDataStatus])

    useEffect(() => {
        if (purchaseOrders.length > 0) {
            setPo(purchaseOrders[0]);
        }else{
            setShowLoader(true);
        }
    }, [purchaseOrders]);

    useEffect(() => {
        if (po && po.purchase_order_items) {
            setItems(po.purchase_order_items);
        }
    }, [po]);

    useEffect(() => {
        calculateTonnage();
    }, [items])

    const calculateTonnage = () => {
        let total = 0;
        for (const item of items) {
            total = total + item.quantity_ordered * item.weight_per_packet
        }
        setTonnage(total)
    }
    return (
        <React.Fragment>
            {showLoader ? (
                <Loader />
            ) : showToast ? <ShowToast
                errorMsg={errorMsg}
            /> :
                <div className="bg-grey">
                    {status === "PENDING" ? <DDPoInvoiceForm
                        pageSource="reuploadInvoice"
                        poNumber={poNumber}
                        presignedUrl={presignedUrl}
                        documentPath={documentPath} />
                        : status === "COMPLETED" ? <InvoiceUploaded
                            po={po}
                            items={items}
                            tonnage={tonnage}
                            pageSource="reuploadInvoice"
                        /> : <></>}
                </div>

            }
        </React.Fragment>
    );
};

export default ReuploadInvoice;
