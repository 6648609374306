import React, { useEffect, useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Details,
  DottedLine,
  FlexColumn,
  SeparatorLine,
  SushiModal,
  VerticalLine,
  WhiteWrapper,
  Wrapper,
} from "../Common/styles.common";
import { Circle } from "./styles.po";
import { Loader } from "../Common/Loader";
import moment from "moment";
import { strings } from "utils/constants/strings";
import { SELLER_STATUS_APPROVED, STATUS_BEGIN, STATUS_DONE } from "utils/constants/others";
import BackButton from "../Button/BackButton";
import { CardWrapper } from "../GRN/styles.grn";

const PoDetails = (props) => {
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  const [po, setPo] = useState("");
  const [items, setItems] = useState("");
  let { source,purchaseOrderId } = useParams();
  const [openPoApprovedModal, setOpenPoApprovedModal] = useState(false);
  const updateSellerStatus = useVendorStore(
    (state) => state.updateSellerStatus
  );
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [tonnage,setTonnage] = useState(0)

  useEffect(() => {
    getPurchaseOrders({
      is_item_required: true,
      purchase_order_id: purchaseOrderId,
    },true);
  }, []);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
      setPo(purchaseOrders[0]);
    }
  }, [purchaseOrders]);

  useEffect(() => {
    if (po.purchase_order_items) {
      setItems(po.purchase_order_items);
    }
  }, [po]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPurchaseOrderStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [getPurchaseOrderStatus]);

  const closeApprovePoModal = () => {
    setOpenPoApprovedModal(false);
    if(source==="all"){
      navigate("/po/all")
    }
    else{
      navigate("/po/pending");
    }
  };

  const handleApprovePoCTA = (id) => {
    updateSellerStatus({
      entity_id: id,
      entity_type: "PURCHASE_ORDER",
      seller_status: SELLER_STATUS_APPROVED,
    });
    setOpenPoApprovedModal(true);
  };

  useEffect(()=>{
    calculateTonnage();
  },[items])

  const calculateTonnage =()=>{
    let total = 0;
    for(const item of items){
      total=total+ item.quantity_ordered* item.weight_per_packet
    }
    setTonnage(total)
  }

  return (
    <React.Fragment>
      {showLoader ? (
        <Loader />
      ) : (
        <Wrapper className="d-flex flex-column">
          <div className="d-flex flex-column y-scroll">
            <Details>
              <div className="d-flex ml-4">
                <BackButton pathName={source==="all" ? "/po/all" : "/po/pending"} />
                <div className="d-flex flex-column mr-4 ml-1">
                    <span className="fs-18 fw-700">{po.purchase_order_number}</span>
                    <span className="fs-12 fw-400 text-gray-cool mt-1">
                    {items.length} product{items.length > 1 ? "s" : ""}
                    {" "}•{" "}{tonnage>999 ? (tonnage/1000).toFixed(2) : tonnage.toFixed(2)} {tonnage>999 ? "tonne": "kg"}
                    </span>
                </div>
              </div>  
              <SeparatorLine />
              <div className="d-flex flex-column mx-4">
                <div className="d-flex align-items-center">
                  <Circle>
                    <img
                      src="/logos/rupee_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  <span className="fs-14 text-gray-cool ml-3">&#8377;</span>
                  {po && (
                    <span className="fs-14 fw-400 text-gray-cool">
                      {po.po_amount.toLocaleString("en-IN")} {strings.poAmount}
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Circle>
                    <img
                      src="/logos/delivery_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  {po.expected_delivery_date ? (
                    <span className="fs-14 fw-400 text-gray-cool ml-3">
                      Delivery by{" "}
                      {moment(new Date(po.expected_delivery_date))
                        .format("DD MMM, YYYY")
                        .toString()}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Circle>
                    <img
                      src="/logos/location_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  <span className="fs-14 fw-400 text-gray-cool ml-3 wd-po-card">
                    {po.warehouse_details?.name}
                  </span>
                </div>
              </div>
            </Details>
            <span className="fs-20 fw-700 ml-4 mt-5">{strings.poDetails}</span>
            <div className="mx-4 my-4">
              {items != "" ? (
                items.map((item, index) => (
                  <Card className="mb-4">
                    <div className="d-flex mx-4">
                      <img
                        src={item.product_image}
                        height="48px"
                        width="48px"
                        style={{borderRadius:"8px"}}
                      />
                      <div className="d-flex flex-column justify-content-center ml-4">
                        <span className="fs-16 fw-600 mb-1">
                          {item.product_name}
                        </span>
                        <span className="fs-15 fw-400 text-gray-cool">
                          &#8377; {item.price_per_unit}/{item.buy_measurement_unit}
                        </span>
                      </div>
                    </div>
                    
                    <Card hideShadow={true} className="align-items-center mx-4 mt-3">
                      <div className="d-flex wd-100 justify-content-center">
                        <div className="d-flex flex-column wd-50 align-items-center">
                          <span className="fs-15 fw-700">
                            {item.quantity_ordered} {item.buy_measurement_unit}
                          </span>
                          <span className="fs-13 fw-400 text-gray-cool mt-2">
                            {strings.orderQty}
                          </span>
                        </div>
                        <VerticalLine />
                        <div className="d-flex flex-column wd-50 align-items-center">
                          <span className="fs-15 fw-700">
                            &#8377;{(item.quantity_ordered*item.price_per_unit)?.toLocaleString("en-IN")}
                          </span>
                          <span className="fs-13 fw-400 text-gray-cool mt-2">
                            {strings.orderAmount}
                          </span>
                        </div>
                      </div>
                    </Card>
                    
                  </Card>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          {po.seller_status != "APPROVED" ? (
            <WhiteWrapper className="px-4">
              <Button
                className="fs-15 text-white fw-600"
                onClick={() => handleApprovePoCTA(po.id)}
              >
                {strings.poAccept}
              </Button>
            </WhiteWrapper>
          ) : (
            <></>
          )}
        </Wrapper>
      )}
      <SushiModal visible={openPoApprovedModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/tick_logo.svg" height="80px" width="80px" />
          <span className="fs-22 fw-700 my-4">PO Accepted</span>
          <span
            className="fs-14 fw-500 text-red-500"
            onClick={closeApprovePoModal}
          >
            {" "}
            Okay
          </span>
        </FlexColumn>
      </SushiModal>
    </React.Fragment>
  );
};

export default PoDetails;
