import React from "react";
import {
  Button,
  Card,
  SeparatorLine,
  VerticalLine,
} from "../Common/styles.common";
import { Navigate, useNavigate } from "react-router-dom";
import { CardWrapper } from "./styles.grn";
import moment from "moment";

export const GrnCard = (props) => {
  const grn = props.grn;
  const navigate = useNavigate();

  const handleViewDetailsCTA = () => {
    if(props.source==="all"){
      navigate(`/grn/details/all/${grn.purchase_order_id}/${grn.id}`);    
    }
    else{
      navigate(`/grn/details/pending/${grn.purchase_order_id}/${grn.id}`);
    }
  };

  return (
    <React.Fragment>
      <Card className="mt-4 mx-4">
        <div className="d-flex flex-column ml-4 mr-4">
          <span className="fs-18 fw-700">{grn?.grn_number}</span>
          <span className="fs-12 fw-400 text-gray-cool mt-1">
            {grn.po_grn_items.length} product
            {grn.po_grn_items.length > 1 ? "s" : ""}
            {" "}
            • {grn?.purchase_order_number}
          </span>
        </div>
        <CardWrapper bgColor={"#F2F4F7"} className="align-items-center mx-4 mt-4">
          <Card smallRadius={true} className="wd-100 align-items-center">
            <div className="d-flex wd-100 justify-content-center">
              <div className="d-flex wd-50 flex-column align-items-center">
                <span className="fs-15 fw-700">
                  &#8377;{grn.supplier_bill_amount.toLocaleString("en-IN")}
                </span>
                <span className="fs-13 fw-400 text-gray-cool mt-1">
                  Bill Amount
                </span>
              </div>
              <VerticalLine />
              <div className="d-flex wd-50 flex-column align-items-center">
                <span className="fs-15 fw-700">
                  &#8377;{grn.grn_amount.toLocaleString("en-IN")}
                </span>
                <span className="fs-13 fw-400 text-gray-cool mt-1">
                  GRN Amount
                </span>
              </div>
            </div>
          </Card>
          <span className="fs-12 fw-400 text-gray-cool m-3">
            GRN on{" "}
            {moment(new Date(grn.created_at)).format("DD MMM, YYYY").toString()}{" "}
            • Bill no. {grn.supplier_bill_number}
          </span>
        </CardWrapper>
        <div className="mx-4 mt-4">
          <Button onClick={handleViewDetailsCTA}>
            <span className="fs-13 fw-600 text-white">View GRN Details</span>
          </Button>
        </div>
      </Card>
    </React.Fragment>
  );
};
