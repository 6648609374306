import React from "react";
import {
    Title,
    Wrapper,
} from "components/core/Header/styles.header";
import BackButton from "../Button/BackButton";
import HandleLogout from "components/core/Common/Logout"

const TncHeader = () => {
    return (
        <>
            <Wrapper className="d-flex justify-content-between align-items-center fixed-top">
                <Title>
                    <div className="ml-1 align-items-center">Terms & conditions</div>
                </Title>
            </Wrapper>
        </>
    );
};

export default TncHeader;
