import { WhiteWrapper, Wrapper } from "../Common/styles.common";
import React, { useEffect, useState } from "react";
import { strings } from "utils/constants/strings";
import { ConfirmedGrnCard } from "../GRN/ConfirmedGrnCard";
import { GrnCard } from "../GRN/GrnCard";
import useVendorStore from "../HomePage/useVendorStore";
import { Loader } from "../Common/Loader";
import PoCard from "./PoCard";
import { FilterBox } from "./styles.po";
import { GRN_STATUS_COMPLETED, PAYMENT_STATUS_PENDING, PO_STATUS_APPROVED, SELLER_STATUS_APPROVED, SELLER_STATUS_NON_APPROVED, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";
import InfiniteScroll from "react-infinite-scroll-component";
import ShowToast from "../Common/Toast";
import BackButton from "../Button/BackButton";
import { BottomModal, Button, FlexColumn } from "../Common/styles.common";
import Cross from "sushiweb/Icons/all/Cross";

const PoTypeBox = ({ id, activeBox, handleClick, children }) => {
  return (
    <React.Fragment>
      <FilterBox
        clicked={id === activeBox}
        onClick={() => handleClick(id)}
        className="fs-13 fw-500"
      >
        {children}
      </FilterBox>
    </React.Fragment>
  );
};

const AllPoView = () => {
  const {
    getPoGrnMappings,
    getPurchaseOrders,
    getPurchaseOrderStatus,
    getPoGrnMappingStatus,
    purchaseOrders,
    poGrnList,poCount,grnCount
  } = useVendorStore();

  const [activeBox, setActiveBox] = useState(0);
  const outletId = window.localStorage.getItem("outlet_id");
  const [showLoader, setShowLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1)
  const [showToast,setShowToast] =useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [poCreator, setPoCreator] = useState("")
  const [openCallModal, setOpenCallModal] = useState(false)

  const handleClick = (index) => {
    setActiveBox(index);
  };

  useEffect(() => {
    if (activeBox === 0 || activeBox === 1) {
      if (getPoGrnMappingStatus === STATUS_BEGIN) {
        setShowLoader(true);
      }
      if (getPoGrnMappingStatus === STATUS_DONE) {
        setShowLoader(false);
      }
      if (getPoGrnMappingStatus === STATUS_FAIL) {
        setShowLoader(false);
        setShowToast(true);
      }
    } else if (activeBox === 2 || activeBox === 3) {
      if (getPurchaseOrderStatus === STATUS_BEGIN) {
        setShowLoader(true);
      }
      if (getPurchaseOrderStatus === STATUS_DONE) {
        setShowLoader(false);
      }
      if (getPurchaseOrderStatus === STATUS_FAIL) {
        setShowLoader(false);
        setShowToast(true);
      }
    }
  }, [getPurchaseOrderStatus, getPoGrnMappingStatus]);

  useEffect(() => {
    if (activeBox === 0) {
      getPoGrnMappings({
        page_number: 1,
        grn_status: GRN_STATUS_COMPLETED,
        seller_status: SELLER_STATUS_APPROVED,
        outlet_id: outletId,
        is_payment_details_required: true,
        is_seller_status_history_required: true,
        get_cn_data: true
      },true);
    } else if (activeBox === 1) {
      getPoGrnMappings({
        page_number: 1,
        grn_status: GRN_STATUS_COMPLETED,
        seller_status: SELLER_STATUS_NON_APPROVED,
        outlet_id: outletId,
        is_po_grn_items_required:true,
        payment_status: PAYMENT_STATUS_PENDING
      },true);
    } else if (activeBox === 2) {
      getPurchaseOrders({
        vendor_id: outletId,
        is_item_required: true,
        po_status: PO_STATUS_APPROVED,
        seller_status: SELLER_STATUS_APPROVED,
        is_po_grn_mapping_required: false,
        fetch_po_with_no_grns:true,
      },true);
    } else if (activeBox === 3) {
      getPurchaseOrders({
        vendor_id: outletId,
        is_item_required: true,
        po_status: PO_STATUS_APPROVED,
        seller_status: SELLER_STATUS_NON_APPROVED,
        fetch_po_with_no_grns:true,
      },true);
    }
    setPageNumber(1);
  }, [activeBox]);

  let cards = [];
  if (activeBox === 0) {
    if (poGrnList && poGrnList.length) {
      for (let index = 0; index < poGrnList.length; index++) {
        cards.push(<ConfirmedGrnCard key={index} grn={poGrnList[index]} />);
      }
    }
  } else if (activeBox === 1) {
    if (poGrnList && poGrnList.length) {
      for (let index = 0; index < poGrnList.length; index++) {
        cards.push(<GrnCard key={index} grn={poGrnList[index]} source={"all"} />);
      }
    }
  } else if (activeBox === 2 || activeBox === 3) {
    if (purchaseOrders && purchaseOrders.length) {
      for (let index = 0; index < purchaseOrders.length; index++) {
          cards.push(<PoCard 
            key={index} 
            po={purchaseOrders[index]} 
            source={"all"}
            openCallModal={openCallModal} 
            setOpenCallModal={setOpenCallModal} 
            setPhoneNumber={setPhoneNumber}
            setPoCreator={setPoCreator}
          />);
      }
    }
  }

  const handleCall = () => {
    const formattedPhoneNumber = phoneNumber.replace(/\s/g, ''); // Remove any whitespace from the phone number
    window.location.href = `tel:+91${formattedPhoneNumber}`;
  };

  const loadNextPage = () => {
    if (activeBox === 0) {
      getPoGrnMappings({
        page_number: pageNumber+1,
        grn_status: GRN_STATUS_COMPLETED,
        seller_status: SELLER_STATUS_APPROVED,
        outlet_id: outletId,
        is_payment_details_required: true,
        is_seller_status_history_required: true,
        get_cn_data: true,
      },false);
    } else if (activeBox === 1) {
      getPoGrnMappings({
        page_number: pageNumber+1,
        grn_status: GRN_STATUS_COMPLETED,
        seller_status: SELLER_STATUS_NON_APPROVED,
        outlet_id: outletId,
        is_po_grn_items_required:true,
        payment_status: PAYMENT_STATUS_PENDING
      },false);
    } else if (activeBox === 2) {
      getPurchaseOrders({
        page_no:pageNumber+1,
        vendor_id: outletId,
        is_item_required: true,
        po_status: PO_STATUS_APPROVED,
        seller_status: SELLER_STATUS_APPROVED,
        fetch_po_with_no_grns:true,
      },false);
    } else if (activeBox === 3) {
      getPurchaseOrders({
        page_no:pageNumber+1,
        vendor_id: outletId,
        is_item_required: true,
        po_status: PO_STATUS_APPROVED,
        seller_status: SELLER_STATUS_NON_APPROVED,
        fetch_po_with_no_grns:true,
      },false);
    }
    setPageNumber(pageNumber+1)
  }

  return (
    <React.Fragment>
      <Wrapper>
        <WhiteWrapper>
          <div className="d-flex">
            <BackButton pathName={"/"}/>
            <span className="fs-16 fw-600">{strings.allPoHeader}</span>
          </div>
        </WhiteWrapper>
        <div className="d-flex x-scroll p-4">
          <PoTypeBox
            id={0}
            activeBox={activeBox}
            handleClick={() => handleClick(0)}
          >
            {strings.confirmedGrns}
          </PoTypeBox>
          <PoTypeBox
            id={1}
            activeBox={activeBox}
            handleClick={() => handleClick(1)}
          >
            {strings.grnToConfirm}
          </PoTypeBox>
          <PoTypeBox
            id={2}
            activeBox={activeBox}
            handleClick={() => handleClick(2)}
          >
            {strings.noGrn}
          </PoTypeBox>
          <PoTypeBox
            id={3}
            activeBox={activeBox}
            handleClick={() => handleClick(3)}
          >
            {strings.pendingApproval}
          </PoTypeBox>
        </div>
        {showLoader && pageNumber===1 ? (
          <Loader />
        ) : (
          <InfiniteScroll
            className="infinite-scroll"
            height={"82%"}
            dataLength={cards.length}
            hasMore={cards.length < ((activeBox === 0 || activeBox === 1) ? grnCount : poCount)}
            next={loadNextPage}
            loader={<Loader isScrollLoader={true}/>}
          >
            {cards}
          </InfiniteScroll>
        )}
        <BottomModal
          visible={openCallModal}
          onClose={()=>setOpenCallModal(false)}
          showCloseIcon={true}
        >
          <FlexColumn className="align-items-center p-4">
            <Cross color={"#696969"} className="close" onClick={()=>setOpenCallModal(false)}/>
            <img
              src={"../logos/po_creator_logo.svg"}
            />
            <span className="fs-20 fw-700 mt-4">{poCreator}</span>
            <span className="fs-14 fw-400 text-gray-cool mt-3 mb-4">PO creator</span>
            <Button onClick={handleCall}>
              <span className="fs-13 fw-500 text-white">Call</span>
            </Button>
          </FlexColumn>

        </BottomModal>
        {showToast?
        <ShowToast/>
        :
        <></>
        }
      </Wrapper>
    </React.Fragment>
  );
};

export default AllPoView;
