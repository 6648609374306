import React, { useState, useEffect } from "react";
import styles from "./Login.module.scss";
import Button from "sushiweb/Button";
import Input from "sushiweb/InputTypes/Input";
import { PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import Header from "../Header/Header";
import Spinner from "sushiweb/Spinner";
import { strings } from "utils/constants/strings";

const UserInput = (props) => {
  const [isDisable, setIsDisable] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.userInput.length > 0) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [props.userInput]);

  useEffect(() => {
    if (props.loginDetails.sendOtp.begin === true) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }

    if (props.loginDetails.sendOtp.done === true) {
      props.setIsOtpSent(true);
    } else {
      props.setIsOtpSent(false);
    }

    if (props.loginDetails.sendOtp.fail === true) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [props.loginDetails.sendOtp]);

  const errorMessage = () => {
      if (props.isValidationsFailed){
        console.log("here")
        return strings.validationErrorMsg
      }else if(showError){
        return props.error.message
      }else{
        return ""
      }
  };

  return (
    <React.Fragment>
      <Header />
      <div className={`d-flex flex-column align-items-center`}>
        <div
          className={`d-flex justify-content-between w-700 fs-21 wd-90 py-3`}
        >
          <div
            className={` d-flex align-items-center fw-700 fs-21`}
          >
            {" "}
            Let's get started
          </div>
        </div>
        <div className={`fs-13 w-400 wd-90 pb-5 text-gray-cool`}>
          Enter your regisered phone number or email , and we'll send an OTP
        </div>
        <div className={`d-flex flex-column justify-content-center wd-90`}>
          <Input
            type="text"
            label="Email/Phone Number"
            borderColor={PROFILE_INPUT_BORDER}
            value={props.userInput}
            errorMsg={errorMessage()}
            className={styles.InputPhoneNumberStyle}
            onChange={(value) =>
               props.setUserInput(value)
            }
          />

          <Button
            btnColor="red"
            size="compressed"
            className={"sendOtpButton"}
            onClick={props.handleUserInputSubmit}
            disabled={isDisable}
          >
            <span className="fs-15 fw-500">Send OTP</span>
          </Button>
        </div>
      </div>
      {showLoader ? (
        <div className="d-flex wd-100 mt-6 align-items-center justify-content-center">
          <Spinner size="large" color="#EF4F5F" />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default UserInput;
