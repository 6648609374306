import React, {useEffect, useState} from 'react';
import useCommonStore from "../Common/useCommonStore";
import {STATUS_DONE} from "../../../utils/constants/others";
import WhiltelistedFileTypes from "../Common/Logout";

const UploadCard = (props) => {
    const {
        setUpdateInvoiceDetails,
        showFile,
        setShowFile,
        showImages,
        setShowImages,
        imageArray,
        setImageArray,
        pngArray,
        setPngArray,
        setFormData,
        setPdfBlob,
        setShowPopup,
    } = props;
    const uploadedDocumentUrl = useCommonStore((state)=> state.uploadedDocumentUrl)
    const uploadDocumentStatus = useCommonStore((state)=> state.uploadDocumentStatus)
    const [displayImages, setDisplayImages] = useState([]);

    const convertToPngAndEmbed = (imageData) => {
        const img = new Image();
        img.src = imageData;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);

            const pngImageData = canvas.toDataURL('image/png');

            setPngArray([...pngArray, pngImageData]);
        };
    };


    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file && WhiltelistedFileTypes.WhiltelistedFileTypesForDocuments(file.type)) {
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append("fileType", "supplier_bill_image");
            setFormData(formData)
            const blob = new Blob([file], { type: 'application/pdf' });
            setPdfBlob(blob)
            setShowFile(true)
        } else {
            setShowPopup(true);
        }
    };

    const handleImageInputChange = async (e) => {
        const file = e.target.files[0];
        if (file && WhiltelistedFileTypes.WhiltelistedFileTypes(file.type)) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageData = e.target.result;
                setImageArray([...imageArray, imageData]);
                setDisplayImages([...displayImages, imageData]);
                convertToPngAndEmbed(imageData);
            };

            reader.readAsDataURL(file);
            setShowImages(true)
        } else {
            setShowPopup(true);
        }
    };

    useEffect(() => {
        if (uploadDocumentStatus === STATUS_DONE) {
            setUpdateInvoiceDetails(uploadedDocumentUrl, "InvoiceUrl")
            setShowFile(true)
        }
    }, [uploadDocumentStatus]);

    const handleImageIconClick = () => {
        document.getElementById('imageInput').click();
    };

    const handleDocumentIconClick = () => {
        document.getElementById('documentInput').click();
    };

    const resetFile =() => {
        setShowFile(false)
        setUpdateInvoiceDetails("", "InvoiceUrl")
    };

    const removeImage =(index) => {
        setImageArray((prevImages) => prevImages.filter((_, i) => i !== index));
        setPngArray((prevImages) => prevImages.filter((_, i) => i !== index));
    }

    useEffect(() => {
        if (imageArray.length === 0) {
            setShowImages(false)
        }
    }, [imageArray]);

    return (
        !showFile && !showImages ?
        <div>
            <input type="file" accept="image/*" onChange={handleImageInputChange} style={{ display: "none" }} id="imageInput" />
            <input type="file" accept="application/pdf" onChange={handleFileInputChange} style={{ display: "none" }} id="documentInput" />
            <img
                src={"../../logos/upload_image.svg"}
                alt="Upload Image"
                style={{ cursor: "pointer" }}
                onClick={() => handleImageIconClick()}
                width="45%"
            />
            <img
                src={"../../logos/upload_doc.svg"}
                alt="Upload Document"
                style={{ cursor: "pointer" }}
                onClick={() => handleDocumentIconClick()}
                width="45%"
                className="ml-4"
            />

        </div> : showImages ?
            <div>
                <div className="d-flex flex-wrap">
                <input type="file" accept="image/*" onChange={handleImageInputChange} style={{ display: "none" }} id="imageInput" />
                <img
                    src={"../../logos/upload_image.svg"}
                    alt="Upload Image"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleImageIconClick()}
                    height="70px"
                    width="95px"
                />
                {imageArray.map((image, index) => (
                        <div id={`Image-${index}`} key={index} className="img-rectangle ml-3 mt-2">
                            <span className="close-icon" onClick={() => removeImage(index)}>&#10006;</span>
                            <img className="image-preview" src={image} alt={`Image ${index}`}/>
                        </div>
                    ))}
                </div>
            </div>
            :
        <div className="rectangle">
            <span className="close-icon" onClick={resetFile}>&#10006;</span>
            <img className="image-preview" src={"../../logos/pdf.svg"} alt="Preview"/>
            <p>Invoice.pdf</p>
        </div>
    );
};

export default UploadCard;
