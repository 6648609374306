import React, { useState } from "react";
import {
  Title,
  Wrapper,
  NewTitle,
  Logo
} from "components/core/Header/styles.header";

import {
  SubBox
} from "components/core/HomePage/styles.homepage"

import {Card,SeparatorLine, WhiteWrapper,BottomModal,FlexColumn} from "../Common/styles.common";
import { border } from "styled-system";


const Header = ({ showLogout, handleLogout,showSubHeader, sellerDetail, allSellerDetails}) => {
  const [showOutletSwithBottomSheet,setShowOutletSwithBottomSheet] = useState(false);


  const setNewOutletIdInLocalStorage = (sellerDetail)=> {
    window.localStorage.setItem("outlet_id",sellerDetail.outlet_id);
    window.localStorage.setItem("outlet_name",sellerDetail.outlet_name);
    setShowOutletSwithBottomSheet(false);
    window.location.reload(false);
  }
  return (
    <>
      <Wrapper className="d-flex justify-content-between align-items-center home-Header">
        <Title>
          <img src="./logos/hplogo.svg" />
          <Logo p={[1]}>seller</Logo>
        </Title>
        {showLogout && (
          <div className="d-flex align-items-center" onClick={handleLogout}>
          <img
            src="/logos/logout_logo.svg"
            height="20px"
            width="20px"
          />
          <span className="fs-14 fw-600 text-red-500 ml-1">Logout</span>
          </div>
        )}
      </Wrapper>
      
      {showSubHeader && <SeparatorLine mt= {"0px"} mb = {"0px"}/>}
      {showSubHeader && <div className="off-white"><SubBox>
        <div className="d-flex justify-content-between align-items-center">
      <div className="fs-13 w-600 fw-600 medium-dark-gray"> 
      {sellerDetail?.city}&nbsp; • &nbsp;
      </div>

      <div className="fs-13 w-600 fw-600 text-red-500"
        onClick={()=> {
          setShowOutletSwithBottomSheet(true);
        }} 
      > 
        Change Outlet
        <img src={"/logos/chevron-down-large.svg"} className="ml-2"/>
      </div>
      </div>
        </SubBox>
        </div>}    
        {showOutletSwithBottomSheet &&  <BottomModal
          visible={showOutletSwithBottomSheet}
          onClose={()=>setShowOutletSwithBottomSheet(false)}
          showCloseIcon={true}
        >
          <div className="fs-18 fw-700 mt-4 mb-2 ml-4">Select an Outlet</div>
          <div>
            {allSellerDetails ? (
              allSellerDetails.map((item, index) => {
                let borderCss = ""
                if (index === 0){
                  borderCss = "1px solid #e23844"
                }
                return (
                  <Card className="mt-2 mb-2 mx-4 my-4" border = {borderCss} onClick={() => setNewOutletIdInLocalStorage(item)}>
                    <div className="d-flex align-items-center ml-4">
                      <FlexColumn className="ml-2">
                        <span className="fs-15 fw-600 mb-1 sushi-gray-dark">{item.city}</span>
                        <span className="fs-13 fw-400 medium-dark-gray">
                          {item.outlet_name}
                        </span>
                      </FlexColumn>
                    </div>
                  </Card>
                )
              })
            ) : (
              <></>
            )}
            </div>
          

        </BottomModal>}
    </>
  );
};

export default Header;
