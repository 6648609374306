import React, { useState } from "react";
import "../../../styles/buttons.scss";
import TncHeader from "../Header/TncHeader";
import HandleLogout from "components/core/Common/Logout"
import {Wrapper} from "../HomePage/styles.homepage";
import {Button, FlexColumn, SushiModal} from "../Common/styles.common";
import {useNavigate} from "react-router-dom";
import {strings} from "../../../utils/constants/strings";
import InfiniteScroll from "react-infinite-scroll-component";
import {Loader} from "../Common/Loader";
import useVendorStore from "../HomePage/useVendorStore";
import {SELLER_STATUS_APPROVED} from "../../../utils/constants/others";

const Tnc = () => {

    const sellerDetails = useVendorStore((state) => state.sellerDetails);
    const updateSellerStatus = useVendorStore((state)=> state.updateSellerStatus)
    const navigate = useNavigate();
    Date.prototype.today = function () {
        return (this.getUTCFullYear()) + "-" + (((this.getUTCMonth()+1) < 10)?"0":"") + (this.getUTCMonth()+1) + "-" + ((this.getUTCDate() < 10)?"0":"") + this.getUTCDate();
    }
    Date.prototype.timeNow = function () {
        return ((this.getUTCHours() < 10)?"0":"") + this.getUTCHours() +":"+ ((this.getUTCMinutes() < 10)?"0":"") + this.getUTCMinutes() +":"+ ((this.getUTCSeconds() < 10)?"0":"") + this.getUTCSeconds();
    }
    const navigateToHome = () => {
        navigate("/");
        let newDate = new Date();
        let dateTime = newDate.today() + "T" + newDate.timeNow() + "Z"
        updateSellerStatus({
            entity_type: "UPDATE_SELLER_TNC_ACCEPTED_TIMESTAMP",
            seller_status: SELLER_STATUS_APPROVED,
            account_id: window.localStorage.getItem("outlet_id"),
            tnc_accepted_time: dateTime,
        })
    }
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const toggleConfirmationModal = () => {
        setOpenConfirmationModal(!openConfirmationModal)
    }

    return (
        <React.Fragment>
            <TncHeader />
            <Wrapper>
                <div className="scrollable-div">
                    <h4>
                        {strings.tncHeading}
                    </h4>
                    <body>
                        <div className="mr-5" dangerouslySetInnerHTML={{ __html: strings.tncSubHeading }} />
                        <div className="mr-5" dangerouslySetInnerHTML={{ __html: strings.tncPreBody }} />
                        <div className="mr-5" dangerouslySetInnerHTML={{ __html: strings.tncListItems }} />
                    </body>
                    <div className="flex bottomDiv">
                        <Button onClick={() => setOpenConfirmationModal(true)} className="mb-1 fs-13 text-red-700 fw-600 width-half bg-white border-red">
                            Decline
                        </Button>
                        <Button onClick={navigateToHome} className="mb-1 ml-4 fs-13 text-white fw-600 width-half">
                            Accept
                        </Button>
                    </div>
                </div>
                <SushiModal visible={openConfirmationModal} isCentered>
                    <FlexColumn className="align-items-center p-5">
                        <img src="/logos/info_logo.svg" height="80px" width="80px" />
                        <span className="fs-21 fw-700 my-4 text-align-center">{strings.tncConfirmationText}</span>
                        <Button
                            className="fs-13 text-white fw-600 mb-3 fs-14 fw-500 text-red-500"
                            onClick={toggleConfirmationModal}
                        >
                            Cancel
                        </Button>
                        <span onClick={HandleLogout} className="fs-14 fw-500 text-red-500">Confirm</span>
                    </FlexColumn>
                </SushiModal>
            </Wrapper>
        </React.Fragment>
    );
};

export default Tnc;
