import React, {useEffect, useState} from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useParams } from "react-router-dom";
import {
    Button, FlexColumn, SeparatorLine, SushiModal,
    WhiteWrapper,
    Wrapper,
} from "../Common/styles.common";
import { Loader } from "../Common/Loader";
import {STATUS_BEGIN, STATUS_DONE, STATUS_FAIL} from "utils/constants/others";
import DDPoHeader from "../Header/DDPoHeader";
import styles from "../Login/Login.module.scss";
import OtpInput from "react-otp-input";
import {errorDisp} from "../Login/styles.login";
import {useNavigate} from "react-router-dom";
import WhiltelistedFileTypes from "../Common/Logout";
import { get } from "styled-system";

const DDPodForm = (props) => {
    const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
    const getPurchaseOrderStatus = useVendorStore(
        (state) => state.getPurchaseOrderStatus
    );
    const validatePodOtp = useVendorStore((state) => state.validatePodOtp);
    const validatePodOtpStatus = useVendorStore(
        (state) => state.validatePodOtpStatus
    );
    const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
    const [po, setPo] = useState("");
    const [items, setItems] = useState("");
    let { source,purchaseOrderId, orderId, goTo } = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [tonnage,setTonnage] = useState(0)
    const [errorMsg, setErrorMsg] = useState(false);
    const [otpPage, setOtpPage] = useState(false);
    const [otp, setOtp] = useState("");
    const [showError, setShowError] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const navigate = useNavigate();
    const [podPages, setPodPages] = useState([])
    const [imageArray, setImageArray] = useState(new Array(5).fill(null))
    const [previewImage, setPreviewImage] = useState([])
    const uploadPodStatus = useVendorStore(
        (state) => state.uploadPodStatus
    );
    const getPresignedUrl = useVendorStore((state) => state.getPresignedUrl);
    const getPresignedUrlStatus = useVendorStore(
        (state) => state.getPresignedUrlStatus
    );
    const podPresignedUrl = useVendorStore((state) => state.podPresignedUrl);
    const podFilePath = useVendorStore((state) => state.podFilePath);
    const uploadPod = useVendorStore((state) => state.uploadPod)
    const [numberOfPodPages, setNumberOfPodPages] = useState(0);
    const [fileTypeErr, setFileTypeErr] = useState(false);
    const [bo, setBo] = useState("");
    const getBuyerOrderPodDetails = useVendorStore((state) => state.getBuyerOrderPodDetails)
    const getBuyerOrderPodDetailsStatus = useVendorStore((state) => state.getBuyerOrderPodDetailsStatus)
    const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO)

    useEffect(() => {
        getPurchaseOrders({
            is_item_required: true,
            purchase_order_id: purchaseOrderId,
        },true);
        if (goTo && goTo === "otp") {
            setOtpPage(true);
        }
    }, []);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
            setPo(purchaseOrders[0]);
        }
        getBuyerOrderPodDetails({
            purchase_order_id: purchaseOrderId,
        })
    }, [purchaseOrders]);

    useEffect(() => {
        if (po.purchase_order_items) {
            setItems(po.purchase_order_items);
        }
    } ,[po])

    useEffect(() => {
        if (getBuyerOrderPodDetailsStatus === STATUS_DONE) {
            for (let i = 0; i < buyerOrdersLinkedtoPO.length; i++) {
                if (buyerOrdersLinkedtoPO[i].pod_order_id === parseInt(orderId)) {
                    setBo(buyerOrdersLinkedtoPO[i])
                }
            }
        }
    }, [getBuyerOrderPodDetailsStatus]);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_BEGIN) {
            setShowLoader(true);
        }
        if (getPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
        }
    }, [getPurchaseOrderStatus]);

    useEffect(() => {

        if (bo.number_of_pod_pages == 0 || bo.is_pod_validated) {
            setOtpPage(true);
        } else if (bo.number_of_pod_pages) {
            setNumberOfPodPages(bo.number_of_pod_pages)
        }
    }, [bo]);

    useEffect(() => {
        setImageArray(new Array(numberOfPodPages).fill(null))
    }, [numberOfPodPages])

    useEffect(()=>{
        calculateTonnage();
    },[items])

    useEffect(() => {
        if (uploadPodStatus === STATUS_DONE) {
            setShowLoader(false);
        }
    }, [uploadPodStatus]);

    const calculateTonnage =()=>{
        let total = 0;
        for(const item of items){
            total=total+ item.quantity_ordered* item.weight_per_packet
        }
        setTonnage(total)
    }

    const navigateToOtpPage = () => {
        if (previewImage.length < numberOfPodPages || errorMsg) {
            setErrorMsg(true);
        } else {
            setOtpPage(true);
        }
    }

    const makeValidateOtp = () => {
        validatePodOtp({
            order_id: parseInt(orderId),
            otp: otp,
            validate_all_pod_pages_uploaded: true,
            purchase_order_id: parseInt(purchaseOrderId)
        })
    }

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        navigate("/ddPo/all");
        window.location.reload();
    };

    const uploadFileToS3 = (file, preSignedUrl) => {
        return fetch(preSignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file?.type,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    setErrorMsg(true);
                }
            }).catch((err) => {
                setErrorMsg(true);
            })
    }

    useEffect(()=>{
        if (validatePodOtpStatus === STATUS_DONE) {
            setOpenConfirmation(true);
        } else if (validatePodOtpStatus === STATUS_FAIL) {
            setShowError(true);
        }
    },[validatePodOtpStatus])

    useEffect(() => {
        if (getPresignedUrlStatus === STATUS_DONE) {
            let fileArr = [podFilePath]
            uploadFileToS3(podPages[podPages?.length - 1], podPresignedUrl)
                .then((data) => {
                    let formData = new FormData();
                    formData.append('orderId', parseInt(orderId));
                    formData.append('imageType', 'invoice');
                    formData.append('file_path_data', JSON.stringify(fileArr));
                    uploadPod(formData)
                })
        }
    }, [getPresignedUrlStatus]);

    useEffect(() => {
        if (uploadPodStatus === STATUS_FAIL) {
            setPreviewImage([]);
            setErrorMsg(true);
            setShowLoader(false);
        }
    }, [uploadPodStatus]);

    const handleImageInputChange = (e) => {
        const file = e.target.files[0];
        if (file && WhiltelistedFileTypes.WhiltelistedFileTypes(file.type)) {
            setErrorMsg(false);
            setShowLoader(true);
            const reader = new FileReader();
            setPodPages([...podPages, file]);
            getPresignedUrl({
                order_id: parseInt(orderId),
            });

            reader.onload = (e) => {
                const imageData = e.target.result;
                setPreviewImage([...previewImage, imageData]);
            };

            reader.readAsDataURL(file);
        } else {
            setFileTypeErr(true);
        }
    };

    return (
        <React.Fragment>
            {showLoader ? (
                <Loader />
            ) : (
                <Wrapper>
                    <DDPoHeader
                        poId={po.id}
                        purchaseOrderNumber={po.purchase_order_number}
                        itemsLength={items.length}
                        tonnage={tonnage}
                        source="podForm"
                    ></DDPoHeader>
                    {otpPage ? <div>
                        <div className="d-flex flex-column po-content">
                            <WhiteWrapper className="invoice-form ml-2 mr-2 mt-1">
                                <div className="d-flex flex-column mr-4 ml-1">
                                    <span className="fs-18 fw-700">Enter verification code</span>
                                    <span className="fs-12 fw-400 text-gray-cool mt-1 mb-4">
                                        shared by customer during delivery
                                    </span>
                                </div>
                                <OtpInput
                                    inputType="number"
                                    containerStyle={`${styles.otpInputField} mb-3`}
                                    value={otp}
                                    onChange={(val) => {
                                        setOtp(val);
                                    }}
                                    numInputs={6}
                                    renderSeperator={"-"}
                                    renderInput={(a) => <input {...a} />}
                                />
                                {showError ? (
                                    <errorDisp className="fs-12 text-red-700">
                                        Wrong OTP Entered
                                    </errorDisp>
                                ) : (
                                    <></>
                                )}
                            </WhiteWrapper>
                        </div>
                        <WhiteWrapper className="po-bottom">
                            <Button
                                className="fs-13 text-white fw-600"
                                onClick={makeValidateOtp}
                            >
                                Mark delivered
                            </Button>
                        </WhiteWrapper>
                        <SushiModal visible={openConfirmation} isCentered>
                            <FlexColumn className="align-items-center p-5">
                                <img src="/logos/tick_logo.svg" height="80px" width="80px" />
                                <span className="fs-22 fw-700 my-4">Order delivered</span>
                                <span
                                    className="fs-14 fw-500 text-red-500"
                                    onClick={closeConfirmation}
                                >{" "}
                                    Okay
                                </span>
                            </FlexColumn>
                        </SushiModal>
                    </div> :
                    <div>
                        <div className="d-flex flex-column po-content">
                            <WhiteWrapper className="invoice-form ml-2 mr-2 mt-1">
                                <div className="d-flex flex-column mr-4 ml-1">
                                    <span className="fs-18 fw-700">Upload delivery challan</span>
                                    <span className="fs-12 fw-400 text-gray-cool mt-1">
                                        with signature and issue
                                    </span>
                                    <span className="fs-9 fw-400 text-gray-cool mt-1">(supported file types are .jpeg, .jpg, .png and .tiff)</span>
                                </div>
                                <SeparatorLine />
                                <div className="d-flex flex-wrap">
                                    {imageArray.map((box, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="box ml-2 mt-1">
                                                    {previewImage.length > index ? (
                                                        <img
                                                            className="image-preview"
                                                            src={previewImage[index]}
                                                            alt={`Image-${index}`}
                                                            id={`Image-icon-${index}`}
                                                        />
                                                    ) : (
                                                        <div className="d-flex flex-column">
                                                            <input src={"/logos/camera_add.svg"} type="file"
                                                                   accept="image/*" onChange={(e) => handleImageInputChange(e)}
                                                                   style={{display: "none"}}
                                                                   id={`imageInput-${index}`}/>
                                                            <label htmlFor={`imageInput-${index}`}>
                                                                <img
                                                                    className="icon"
                                                                    src={"/logos/camera_add.svg"}
                                                                    width="25px"
                                                                    height="25px"
                                                                    alt="Upload Image"
                                                                    id={`Image-icon-${index}`}
                                                                />
                                                            </label>
                                                            <span className="fs-10 fw-400 text-red-500">{`Page ${index + 1}`}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {errorMsg ? (
                                    <errorDisp className="fs-12 text-red-700">
                                        Please add delivery challan
                                    </errorDisp>
                                ) : fileTypeErr ?
                                    <errorDisp className="fs-12 text-red-700">
                                        Please upload images with correct file type
                                    </errorDisp>
                                : (
                                    <></>
                                )}
                            </WhiteWrapper>
                        </div>
                        <WhiteWrapper className="po-bottom">
                            <Button
                                className="fs-13 text-white fw-600"
                                onClick={navigateToOtpPage}
                            >
                                Verify with OTP
                            </Button>
                        </WhiteWrapper>
                    </div>}
                </Wrapper>
            )}
        </React.Fragment>
    );
};

export default DDPodForm;
