import React, {useState} from "react";
import {
    BottomModal,
    Card, FlexColumn,
} from "../Common/styles.common";
import { CardWrapper, InvoiceButton, PaidTag, UnpaidTag } from "../../core/GRN/styles.grn";
import moment from "moment";
import { strings } from "utils/constants/strings";
import {DownloadInvoice,getPdfBytes} from "../../../operations/operations";
import useCommonStore from "../Common/useCommonStore";
import { v4 as uuidv4 } from "uuid";

export const DDCompletedCard = (props) => {
    const po = props.po;
    const uploadDocs = useCommonStore((state)=> state.uploadDocs);
    const [showPaymentDetailsBottomSheet,setShowPaymentDetailsBottomSheet] = useState(false);

    const showPaymentDetails = () => {
        setShowPaymentDetailsBottomSheet(true);
    }

    const downloadInvoice =() => {
        if(po.order_bill_image){
            DownloadInvoice(po.order_bill_image, `Invoice-${po.purchase_order_number}`);
            if(window?.downloadFileMessageHandler){
                downloadBlobAndSendJsEvent(po.order_bill_image,po.purchase_order_number);
            }
        }
    }

    const downloadBlobAndSendJsEvent = async (url,poNumber) => {
        let fileBytes = await getPdfBytes(url)
        let formData = new FormData();
        const blob = new Blob([fileBytes], { type: 'application/pdf' });
        let fileName = `Invoice-${poNumber}-${uuidv4()}.pdf`
        formData.append("fileType", "supplier_bill_image");
        formData.append("file", blob);
        formData.append("fileName", fileName);
        uploadDocs(formData,(url)=>{
            if(window?.downloadFileMessageHandler){
                window.downloadFileMessageHandler.postMessage(url);
            }
        });
    }

    return (
        <React.Fragment>
            <Card className="mt-4 mx-4">
                <div className="d-flex flex-column ml-4 mr-4">
                    <span className="fs-18 fw-700">{po.purchase_order_number}</span>
                </div>
                <CardWrapper bgColor={"#F2F4F7"} className="align-items-center mx-4 mt-4">
                    <Card smallRadius={true} className="wd-100 align-items-center">
                        <div className="d-flex wd-100 justify-content-between px-4 align-items-center">
                            <div className="d-flex flex-column align-items-center">
                                <span className="fs-15 fw-700">&#8377;{po.total_supplier_bill_amount}</span>
                                <span className="fs-13 fw-400 text-gray-cool mt-1">
                  Invoice amount
                </span>
                            </div>
                            {po["purchase_order_payment"]["id"] != 0 ? (
                                <PaidTag>
                  <span className="fs-11 fw-500" onClick={() => showPaymentDetails()}>
                    {strings.paymentDone}{" "}
                      {moment(
                          new Date(po["purchase_order_payment"]["payment_date"])
                      )
                          .format("DD MMM, YYYY")
                          .toString()}
                      <span className={"ml-1"}>
                          {">"}
                      </span>
                  </span>
                                </PaidTag>
                            ) : (
                                <UnpaidTag>
                  <span className="fs-11 fw-500">
                    {strings.paymentProcessing}
                  </span>
                                </UnpaidTag>
                            )}
                        </div>
                    </Card>
                        <span className="fs-12 fw-400 text-gray-cool m-3">
              Delivered on{" "}
                            {moment(
                                new Date(po["po_consideration_date"])
                            )
                                .format("DD MMM, YYYY")
                                .toString()}
            </span>
                </CardWrapper>
                <div className="mx-4 mt-4">
                    <InvoiceButton onClick={downloadInvoice}>
                        <img src="/logos/invoice_logo.svg" height="16px" width="16px" />
                        <span className="fs-13 fw-600 text-red-500 ml-2">
              Download invoice
            </span>
                    </InvoiceButton>
                </div>
            </Card>
            {showPaymentDetailsBottomSheet && <BottomModal
                visible={showPaymentDetailsBottomSheet}
                onClose={()=>setShowPaymentDetailsBottomSheet(false)}
                showCloseIcon={true}
            >
                <div className="fs-18 fw-700 mt-4 mb-2 ml-4">Payment details</div>
                <div>
                    {po.purchase_order_payment ? (
                        <Card className="mt-2 mb-2 mx-4 my-4">
                            <div className="d-flex align-items-center ml-4">
                                <FlexColumn className="ml-2">
                                    <span className="fs-15 fw-600 mb-1 sushi-gray-dark">{po.purchase_order_payment.payment_method} {"payment"}</span>
                                    <span className="fs-13 fw-400 mb-1 medium-dark-gray">
                      {"UTR no: "}{po.purchase_order_payment.transaction_info}
                    </span>
                                    <span className="fs-13 fw-400 medium-dark-gray">
                      {moment(
                          new Date(po.purchase_order_payment.payment_date)
                      )
                          .format("DD MMM, YYYY")
                          .toString()}
                    </span>
                                </FlexColumn>
                            </div>
                        </Card>
                    ) : (
                        <></>
                    )}
                </div>


            </BottomModal>}
        </React.Fragment>
    );
};
