import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.bgColor};
  border: ${(props) => (props.showBorder ? "1px solid #E6E9EF" : "")};
  border-radius: 12px;
  padding: 1px;
`;
export const UnpaidTag = styled.div`
  display: flex;
  justifyt-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background: #256fef;
  color: white;
  border-radius: 100px;
  height: fit-content;
`;

export const PaidTag = styled.div`
  display: flex;
  justifyt-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background: #119199;
  color: white;
  border-radius: 100px;
  height: fit-content;
`;

export const InvoiceButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ef4f5f;
`;

export const InvoiceButtonWithDownCNButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 40px;
  width: 50%;
  border-radius: 8px;
  border: 1px solid #ef4f5f;
`;


export const DownloadCreditNoteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EF4F5F;
  height: 32px;
  width: 30%;
  border-radius: 10px;
  border: 1px solid #ef4f5f;
  color: white;
  span {
    color: white;
  }
`;

export const ViewCreditNoteButton = styled.button`
display: flex;
justify-content: center;
align-items: center;
background: #EF4F5F;
height: 40px;
width: 50%;
border-radius: 8px;
border: 1px solid #ef4f5f;
color: white;
span {
  color: white;
}
`;

export const MandiTag = styled.div`
  display: flex;
  justifyt-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background: #119199;
  color: white;
  border-radius: 100px;
  height: fit-content;
`;