import React, { useEffect, useState } from "react";
import { Circle } from "./styles.po";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Card, SeparatorLine } from "../Common/styles.common";
import {InvoiceButton} from "../GRN/styles.grn";
import {DownloadInvoice,getPdfBytes} from "../../../operations/operations";
import useCommonStore from "../Common/useCommonStore";
import { v4 as uuidv4 } from "uuid";
import { MandiTag } from "../GRN/styles.grn";
import { PO_ORDER_TAG_DIRECT_DELIVERY, PO_ORDER_TAG_MANDI_DD } from "utils/constants/others";

const DDPoCard = (props) => {
    const type = props.type;
    const po = props.po;
    const [tonnage,setTonnage] = useState(0)
    const navigate = useNavigate();
    const uploadDocs = useCommonStore((state)=> state.uploadDocs);
    const handleViewDetailsCTA = (source) => {
        navigate(`/ddPo/details/${source}/${po.id}`)
    };

    const downloadInvoice =() => {
        if(po.order_bill_image){
            DownloadInvoice(po.order_bill_image, `Invoice-${po.purchase_order_number}`);
            if(window?.downloadFileMessageHandler){
                downloadBlobAndSendJsEvent(po.order_bill_image,po.purchase_order_number);
            }
        }
    }
    useEffect(()=>{
        calculateTonnage();
    },[])

    const calculateTonnage =()=>{
        let total = 0;
        for(const item of po.purchase_order_items){
            total=total+ item.quantity_ordered* item.weight_per_packet
        }
        setTonnage(total)
    }

    const downloadBlobAndSendJsEvent = async (url,poNumber) => {
        let fileBytes = await getPdfBytes(url)
        let formData = new FormData();
        const blob = new Blob([fileBytes], { type: 'application/pdf' });
        let fileName = `Invoice-${poNumber}-${uuidv4()}.pdf`
        formData.append("fileType", "supplier_bill_image");
        formData.append("file", blob);
        formData.append("fileName", fileName);
        uploadDocs(formData,(url)=>{
            if(window?.downloadFileMessageHandler){
                window.downloadFileMessageHandler.postMessage(url);
            }
        });
    }

    return (
        <React.Fragment>
            <Card className="mt-4 mx-4">
                <div className="d-flex justify-content-between px-4">
                    <div className="d-flex flex-column wd-100">
                        <div className="d-flex flex-row wd-100 justify-content-between">
                            <span className="fs-18 fw-700">{po.purchase_order_number}</span>
                            {po.order_tag === PO_ORDER_TAG_MANDI_DD ? (<MandiTag><span className="fs-12 fw-300">MANDI</span></MandiTag>) : null} 
                        </div>
                        <span className="fs-12 fw-400 text-gray-cool mt-1">
              {" "}
                            {po.purchase_order_items.length} product
                            {po.purchase_order_items.length > 1 ? "s" : ""}
                            {" "}•{" "}
                            {tonnage>999? (tonnage/1000).toFixed(2): tonnage.toFixed(2)} {tonnage>999 ? "tonne":"kg"}
            </span>
                    </div>
                </div>
                <SeparatorLine />
                <div className="d-flex flex-column ml-4 mr-4">
                    <div className="d-flex align-items-center">
                        <Circle className="mr-3">
                            <img src="../logos/rupee_logo.svg" height="16px" width="15px" />
                        </Circle>
                        <span className="fs-14 text-gray-cool">&#8377;</span>
                        <span className="fs-14 fw-400 text-gray-cool">
              {po.po_amount.toLocaleString("en-IN")} PO Amount
            </span>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <Circle className="mr-3">
                            <img
                                src="../logos/delivery_logo.svg"
                                height="16px"
                                width="15px"
                            />
                        </Circle>
                        <span className="fs-14 fw-400 text-gray-cool">
              Delivery by{" "}
                            {moment(new Date(po.expected_delivery_date))
                                .format("DD MMM, YYYY")
                                .toString()}
            </span>
                    </div>
                    {po.order_tag === PO_ORDER_TAG_DIRECT_DELIVERY ? (
                        <div className="d-flex align-items-center justify-content-between mt-3">
                            <Circle className="mr-3">
                                <img
                                    src="../logos/location_logo.svg"
                                    height="16px"
                                    width="15px"
                                />
                            </Circle>
                            <span className="fs-14 fw-400 text-gray-cool wd-po-card">
                                {po.buyer_outlet_details.name}
                            </span>
                        </div>
                    ) : null}
                    
                </div>
                <SeparatorLine />
                <div className="ml-4 mr-4">
                    {type === "pending" ?
                        <div className="d-flex">
                            <InvoiceButton className="wd-45" onClick={downloadInvoice}>
                                <span className="fs-13 fw-500 text-red">Download invoice</span>
                            </InvoiceButton>
                            <Button className="ml-5 wd-45" onClick={() => handleViewDetailsCTA("pod")}>
                                <span className="fs-13 fw-500 text-white">View PO Details</span>
                            </Button>
                        </div>
                        : <Button onClick={() => handleViewDetailsCTA("invoice")}>
                        <span className="fs-13 fw-500 text-white">View PO Details</span>
                    </Button>}
                </div>
            </Card>
        </React.Fragment>
    );
};

export default DDPoCard;
