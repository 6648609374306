import moment from "moment/moment";
import number2text from "number2text";
import React from "react";
import { strings } from "utils/constants/strings";
import { useRef, useEffect } from "react";

const MapCreditNoteData = (props) => {
  const { cnData } = props;
  const poDisplayRef = useRef();

  const checkEmailForFarmerOutlet = (email) => {
    if (
      window.localStorage.getItem("seller-type") &&
      window.localStorage.getItem("seller-type") === strings.farmerOutlet
    ) {
      let invalidEmail = strings.blockedEmailHandleListForFarmer.map(
        (handler) => {
          if (new RegExp(handler).test(email)) {
            return 1;
          }
        }
      );
      if (invalidEmail.includes(1)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    poDisplayRef.current && props.setCnHtmlData(poDisplayRef.current.innerHTML);
  }, [poDisplayRef]);

  return (
    <React.Fragment>
      <div className="hide-pdf-container" ref={poDisplayRef}>
        <div
          style={{
            width: "550px",
            textAlign: "center",
            fontSize: "20px",
            paddingBottom: "12px",
          }}
        >
          <strong>{cnData.seller_info?.outlet_name}</strong>
        </div>

        <div
          style={{
            width: "550px",
            textAlign: "center",
            borderWidth: "1px 1px 1px 1px",
            borderStyle: "solid",
            fontSize: "12px",
          }}
        >
          <strong>Credit note</strong>
        </div>

        <div style={{ width: "550px" }} id="entry-po-display">
          <div
            style={{
              width: "550px",
              textAlign: "left",
              borderWidth: "0px 1px 0px 1px",
              borderStyle: "solid",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Outlet Name :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>{cnData.seller_info.outlet_name}</strong>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Phone number :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>{cnData?.seller_info?.phone_number}</strong>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "4px",
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Email :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>
                  {!checkEmailForFarmerOutlet(cnData?.seller_info?.email)
                    ? cnData?.seller_info?.email
                    : "-"}
                </strong>
              </span>
            </div>
          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              flexDirection: "column",
              border: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              style={{
                width: "550px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Credit Note Number
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{cnData.cn_number}</strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Order Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>
                    {moment(new Date(cnData.order_date))
                      .format("DD MMM, YYYY")
                      .toString()}
                  </strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Credit Note Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>
                    {moment(new Date(cnData.cn_date))
                      .format("DD MMM, YYYY")
                      .toString()}
                  </strong>
                </div>
              </div>
              <div style={{ padding: "4px", flex: 1 }}>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Delivery Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>
                    {moment(new Date(cnData.grn_completed_on))
                      .format("DD MMM, YYYY")
                      .toString()}
                  </strong>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "550px",
                display: "flex",
                flexDirection: "row",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Place of Supply
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{cnData.warehouse_details?.state}</strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Credit Note Number
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{cnData.cn_number}</strong>
                </div>
              </div>
              <div style={{ borderRight: "0px", padding: "4px", flex: 1 }}>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Payment Status
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong> - </strong>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              flexDirection: "column",
              textAlign: "left !important",
            }}
          >
            <div
              style={{
                width: "550px",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Bill From :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <strong>{cnData.seller_info?.outlet_name}</strong>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Address :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  {cnData.seller_info?.address},{" "}
                  <span style={{ fontSize: "12px" }}>
                    {cnData.seller_info?.city}
                  </span>
                  ,{" "}
                  <span style={{ fontSize: "12px" }}>
                    {cnData.seller_info?.state}
                  </span>
                  -
                  <span style={{ fontSize: "12px" }}>
                    {cnData.seller_info?.zip_code}
                  </span>
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  PAN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {cnData.seller_info?.transaction_document_type === "pan"
                    ? cnData.seller_info?.transaction_document_number
                    : "-"}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row"}}>
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  GSTIN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {cnData.seller_info?.gstin !== "" ? cnData.seller_info?.gstin : "-"}
                </span>
              </div>
            </div>

            <div
              style={{
                width: "550px",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "none",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  paddingBottom: "1px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Bill To :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <strong> {cnData.warehouse_details?.name} </strong>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Address :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <span style={{ fontSize: "12px" }}>
                    {cnData.warehouse_details?.billing_address}
                  </span>
                  ,
                  <span style={{ fontSize: "12px" }}>
                    {cnData.warehouse_details?.city}
                  </span>
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  GSTIN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {cnData.warehouse_details?.gstin}
                </span>
              </div>
            </div>
            <div
              style={{
                width: "550px",
                borderWidth: "1px 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "none",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  paddingBottom: "1px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Shipped To :
                </span>

                <strong style={{ width: "70%", fontSize: "12px" }}>
                  {" "}
                  {cnData.warehouse_details?.name}{" "}
                </strong>
              </div>

              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Address :
                </span>

                <span style={{ width: "70%", fontSize: "12px" }}>
                  <span style={{ fontSize: "12px" }}>
                    {cnData.warehouse_details?.shipping_address}
                  </span>
                  ,
                  <span style={{ fontSize: "12px" }}>
                    {cnData.warehouse_details?.city}
                  </span>
                </span>
              </div>

              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "2px",
                }}
              >
                <span
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  GSTIN :
                </span>

                {
                  <span style={{ fontSize: "12px" }}>
                    {cnData.warehouse_details?.gstin}
                  </span>
                }
              </div>
            </div>
          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              fontSize: "12px",
              borderTop: "1px solid",
              borderRight: "1px solid",
              paddingRight: "1px",
            }}
          >
            <div
              style={{
                width: "8%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 1px",
                borderStyle: "solid",
                borderTop: "none",
              }}
            >
              <strong>Sl No.</strong>
            </div>
            <div
              style={{
                width: "25%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Product Name</strong>
            </div>
            <div
              style={{
                width: "12%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>HSN</strong>
            </div>

            <div
              style={{
                width: "14%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Quantity</strong>
            </div>

            <div
              style={{
                width: "14%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Price Per Unit</strong>
            </div>
            <div
              style={{
                width: "12%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>UoM</strong>
            </div>

            <div
              style={{
                width: "15%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 0px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Total</strong>
            </div>
          </div>

          {cnData &&
            cnData.cn_items?.map((item, index) => (
              <div
                className="keep-together"
                style={{
                  width: "550px",
                  display: "flex",
                  fontSize: "12px",
                  borderTop: "1px solid",
                  borderRight: "1px solid",
                  paddingRight: "1px",
                }}
              >
                <div
                  style={{
                    width: "8%",
                    borderWidth: "0 1px 0 1px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                    borderTop: "none",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    width: "25%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.product_name}
                </div>
                <div
                  style={{
                    width: "12%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                    wordBreak: "break-all",
                  }}
                >
                  {item.hsn_code}
                </div>
                <div
                  style={{
                    width: "14%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.cn_quantity}
                </div>
                <div
                  style={{
                    width: "14%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.price_per_unit}
                </div>
                <div
                  style={{
                    width: "12%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.unit_of_measure}
                </div>
                <div
                  style={{
                    width: "15%",
                    borderWidth: "0 0px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  &#8377; {item.cn_amount.toFixed(2)}
                </div>
              </div>
            ))}

          <div
            style={{
              width: "550px",
              display: "flex",
              fontSize: "11px",
              borderRight: "1px solid",
              paddingRight: "1px",
            }}
          >
            <div
              style={{
                width: "8%",
                border: "1px solid",
                borderStyle: "solid",
              }}
            />
            <div
              style={{
                width: "25%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontSize: "11px",
              }}
            >
              <span style={{ fontSize: "12px", marginLeft: "5px" }}>Total</span>
            </div>
            <div
              style={{
                width: "12%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "14%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "14%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "12%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "15%",
                border: "1px solid",
                borderRight: "0px",
                borderStyle: "solid",
                borderLeft: "none",
                textAlign: "right",
              }}
            >
              <strong style={{ fontSize: "12px" }}>
                &#8377; {cnData.total_cn_amount}
              </strong>
            </div>
          </div>
          <div
            style={{
              width: "550px",
              display: "flex",
              padding: "5px 0px",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
            }}
          >
            <div style={{ width: "80%", fontSize: "12px", marginLeft: "5px" }}>
              {" "}
              <span style={{ fontSize: "12px" }}>
                Amount Chargeable (in words)
              </span>{" "}
              <br />
              <strong>
                INR
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  {number2text(cnData.total_cn_amount, "", true)}
                </span>
              </strong>
            </div>
            <div
              style={{
                width: "20%",
                textAlign: "right",
                fontSize: "12px",
                marginRight: "5px",
              }}
            >
              {" "}
              <span className="fs-12">E. &amp; O.E</span>
            </div>
          </div>
          <div className="d-flex">
            <span className="fs-12 text-align-center wd-100 mt-4">
              This is a computer generated credit note and does not need signatures.
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MapCreditNoteData;
