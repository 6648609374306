import React from "react";

const HandleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("outlet_id");
    window.localStorage.removeItem("outlet_name");
    window.localStorage.removeItem("seller-type")
    window.localStorage.removeItem("phone_number");
    if (window && window.logoutChannelMessageHandler) {
        window.logoutChannelMessageHandler.postMessage("true");
    }
    window.location.reload();
};

const WhiltelistedFileTypesForImages = (fileType) => {
    if (fileType === "image/jpeg" || fileType === "image/jpg" || fileType === "image/png" || fileType === "image/tiff") {
        return true
    }
    return false
}

const WhiltelistedFileTypesForDocuments = (fileType) => {
    if (fileType === "application/pdf") {
        return true
    }
    return false
}

export default {HandleLogout, WhiltelistedFileTypes: WhiltelistedFileTypesForImages, WhiltelistedFileTypesForDocuments};
