import { PDFDocument } from "pdf-lib";
import { v4 as uuidv4 } from "uuid";
import useCommonStore from "components/core/Common/useCommonStore";

export const makeEncodedQueryParams = (defaultParams, requestParams) => {
  let queryParams = Object.assign({}, defaultParams);
  let queryString = "?";
  Object.keys(defaultParams).map((ele) => {
    if (
      requestParams[ele] ||
      requestParams[ele] === 0 ||
      requestParams[ele] === false
    ) {
      queryParams[ele] = requestParams[ele];
    }
    queryString =
      queryString + `${ele}=${encodeURIComponent(queryParams[ele])}&`;
  });
  queryString = queryString.substring(0, queryString.length - 1);
  return queryString;
};

export async function makeCombinedPDFDocument(documentsInBytes) {
  const combinedPDF = await PDFDocument.create();

  for (const documentInBytes of documentsInBytes) {
    const document = await PDFDocument.load(documentInBytes, {
      ignoreEncryption: true,
    });
    const pages = await combinedPDF.copyPages(
      document,
      document.getPageIndices()
    );

    for (const page of pages) {
      combinedPDF.addPage(page);
    }
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await combinedPDF.save();

  return pdfBytes;
  // downloadBlob(pdfBytes, combinedPDFFileName, "application/pdf");
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const length = binaryString.length;

  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export const downloadURL = (data, fileName) => {
  const a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.style.display = "none";
  a.click();
  a.remove();
};

export const downloadBlob = (data, fileName, mimeType) => {
  const blob = new Blob([data], {
    type: mimeType,
  });

  const url = window.URL.createObjectURL(blob);
  downloadURL(url, fileName);
  setTimeout(() => window.URL.revokeObjectURL(url), 1000);
}

export async function DownloadPDF(url,fileName,callback) {
  let fetchResult, arrayBufferResult;
  try{
    fetchResult = await fetch(url);
    arrayBufferResult = await fetchResult.arrayBuffer();
    
    const doc = await PDFDocument.load(arrayBufferResult)
    const pdfDoc = await PDFDocument.create();

    
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices())

    for(const page of copiedPages){
      pdfDoc.addPage(page)
    }

    const pdfBytes= await pdfDoc.save()

    downloadBlob(pdfBytes, fileName, "application/pdf")
  } catch(error){
    if (typeof callback === "function") {
      callback(error);}
  }
}

export function DownloadInvoice(url, fileName) {
  fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        downloadBlob(blob, fileName, "application/pdf")
      })
      .catch((err) => {

      })
}


export async function getPdfBytes(url) {
  let fetchResult, arrayBufferResult;
  try{
    fetchResult = await fetch(url);
    arrayBufferResult = await fetchResult.arrayBuffer();
    
  } catch(error){

  }

  const doc = await PDFDocument.load(arrayBufferResult)
  const pdfDoc = await PDFDocument.create();

  
  const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices())

  for(const page of copiedPages){
    pdfDoc.addPage(page)
  }

  const pdfBytes= await pdfDoc.save()

  return pdfBytes;  
}
