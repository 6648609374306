import React, { useEffect, useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useNavigate, useParams } from "react-router-dom";
import DDPoHeader from "../Header/DDPoHeader";
import { Loader } from "../Common/Loader";
import {
    Button,
    Card,
    Details, FlexColumn, SushiModal,
    VerticalLine,
    WhiteWrapper,
    Wrapper,
    SeparatorLine
  } from "../Common/styles.common";
import { STATUS_BEGIN, STATUS_DONE } from "utils/constants/others";
import BoCard from "./BoCard";
import InfiniteScroll from "react-infinite-scroll-component";


const BOView = (props) => {
    const getBuyerOrderPodDetails = useVendorStore((state) => state.getBuyerOrderPodDetails)
    const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO)
    const [showLoader, setShowLoader] = useState(false);
    const [po, setPo] = useState("");
    const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
    let {purchaseOrderId} = useParams();
    const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
    const getPurchaseOrderStatus = useVendorStore((state) => state.getPurchaseOrderStatus);
    const [items, setItems] = useState("");
    const [tonnage,setTonnage] = useState(0);

    let cards = [];
    if (buyerOrdersLinkedtoPO && buyerOrdersLinkedtoPO.length ) { 
        buyerOrdersLinkedtoPO.map((bo, index) => {
            cards.push(
                <BoCard
                    key={index}
                    bo={bo}
                    po={po}
                />
            );
        });
    }

    useEffect(() => {
        getPurchaseOrders({
            is_item_required: true,
            purchase_order_id: purchaseOrderId,
        },true);
        getBuyerOrderPodDetails({
            purchase_order_id: purchaseOrderId,
        })
    },[])

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
          setPo(purchaseOrders[0]);
        }
    }, [purchaseOrders]);

    useEffect(() => {
        if (po.purchase_order_items) {
          setItems(po.purchase_order_items);
        }
      }, [po]);
    
    useEffect(()=>{
        calculateTonnage();
    },[items])

    const calculateTonnage =()=>{
        let total = 0;
        for(const item of items){
          total=total+ item.quantity_ordered* item.weight_per_packet
        }
        setTonnage(total)
    }
    

    return (
        <React.Fragment>
        {showLoader ? (
          <Loader />
        ) : (
        <Wrapper>
            <DDPoHeader
              poId={po.id}
              purchaseOrderNumber={po.purchase_order_number}
              itemsLength={po.purchase_order_items?.length}
              tonnage={tonnage}
              orderTag={po.order_tag}
            ></DDPoHeader>

            <div className="text-align-center pt-3 fs-13 w-600 fw-600 medium-dark-gray">
                Select order to upload delivery challan
            </div>

            <InfiniteScroll
                    className="infinite-scroll"
                    height={"82%"}
                    hasMore = {false}
                    dataLength={buyerOrdersLinkedtoPO.length}
                >
                    {cards}
            </InfiniteScroll> 
        </Wrapper>
        )}
    </React.Fragment>
    );
}

export default BOView;