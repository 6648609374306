import moment from "moment/moment";
import number2text from "number2text";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewPDF from "utils/ViewPDF";
import useVendorStore from "../HomePage/useVendorStore";
import { Loader } from "../Common/Loader";
import {
  Button,
  FlexColumn,
  SushiModal,
  WhiteWrapper,
  Wrapper,
} from "../Common/styles.common";
import useCommonStore from "../Common/useCommonStore";
import { base64ToArrayBuffer, makeCombinedPDFDocument } from "operations/operations";
import { STATUS_BEGIN, STATUS_DONE, STATUS_INIT, STATUS_FAIL, SOURCE_DD, PO_ORDER_TAG } from "utils/constants/others";
import BackButton from "../Button/BackButton";
import { strings } from "utils/constants/strings";
import { CardWrapper } from "./styles.grn";
import Spinner from "sushiweb/Spinner/Spinner";
import GenerateCN from "./generateCN";
import { SELLER_STATUS_APPROVED } from "utils/constants/others";
import ShowToast from "../Common/Toast";
import Cross from "sushiweb/Icons/all/Cross";

const Invoice = () => {
  const createAndGetCnData = useVendorStore(
    (state) => state.createAndGetCnData
  );
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  let { source, grnId,purchaseOrderId } = useParams();
  const getPoGrnMappingStatus = useVendorStore(
    (state) => state.getPoGrnMappingStatus
  );
  const poGrnList = useVendorStore((state) => state.poGrnList);
  const updateSellerStatus = useVendorStore(
    (state) => state.updateSellerStatus
  );
  const updateEntityStatus = useVendorStore(
    (state) => state.updateEntityStatus
  );
  const updateSellerApprovalStatus = useVendorStore((state)=> state.updateSellerApprovalStatus)
  const uploadDocument = useCommonStore((state)=> state.uploadDocument)
  const uploadDocumentStatus = useCommonStore((state)=> state.uploadDocumentStatus)
  const [grn, setGrn] = useState("");
  const [po, setPo] = useState("");
  const [openGrnConfirmedModal, setOpenGrnConfirmedModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false)
  const [pdfBlob, setPdfBlob] = useState(null)
  const [showInvoice,setShowInvoice] = useState(false)
  const outletName = window.localStorage.getItem("outlet_name");
  const navigate = useNavigate();
  const [generateCn, setGenerateCn] = useState(false);
  const [invoiceDocUrl, setInvoiceDocUrl]=useState("");
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const updatePurchaseOrder = useVendorStore((state) => state.updatePurchaseOrder);
  const markCompleteBuyerOrderLinkedToDDPurchaseOrder = useVendorStore((state) => state.markCompleteBuyerOrderLinkedToDDPurchaseOrder);
  const updatePurchaseOrderStatus = useVendorStore((state) => state.updatePurchaseOrderStatus);
  const markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus = useVendorStore((state) => state.markBuyerOrderCompletedLinkedToDDPurchaseOrderStatus);
  const updatePurchaseOrderErrorModalData = useVendorStore((state) => state.updatePurchaseOrderErrorModalData);
  const markBuyerOrderCompletedLinkedToDDPurchaseOrderError = useVendorStore((state) => state.markBuyerOrderCompletedLinkedToDDPurchaseOrderError);
  const markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder = useVendorStore((state) => state.markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder);
  const markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus = useVendorStore((state) => state.markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus);
  const markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderError = useVendorStore((state) => state.markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderError);
  const getBuyerOrderPodDetails  = useVendorStore((state) => state.getBuyerOrderPodDetails);
  const getBuyerOrderPodDetailsStatus = useVendorStore((state) => state.getBuyerOrderPodDetailsStatus);
  const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO);
  const isFirstRender = useRef(true);
  const [openSuccessModel, setOpenSuccessModel] = useState(false);
  useEffect(() => {
    getPoGrnMappings({
      po_grn_mapping_id:grnId,
      is_po_grn_items_required: true,
      get_cn_data: true,
    },true);
    getPurchaseOrders({
      is_item_required: true,
      purchase_order_id: purchaseOrderId,
      is_po_grn_items_required: true,
    },true);
    getBuyerOrderPodDetails({
      purchase_order_id: purchaseOrderId,
    })
  }, []);

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_DONE && poGrnList) {
      setGrn(poGrnList[0]);
    }
  }, [poGrnList]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
      setPo(purchaseOrders[0]);
    }
  }, [purchaseOrders]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_BEGIN || getPoGrnMappingStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPurchaseOrderStatus === STATUS_DONE && getPoGrnMappingStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [getPurchaseOrderStatus, getPoGrnMappingStatus]);

  const poDisplayRef = useRef();

  const poItemIdToGrnItemMap = getGrnItemsMap(grn.po_grn_items);
  const purchaseOrderItems = getModifiedPOItems(
    poItemIdToGrnItemMap,
    po.purchase_order_items
  );

  const totalInvoiceAmount = getTotalInvoiceAmount(purchaseOrderItems);

  function getTotalInvoiceAmount(POItems) {
    let totalInvoiceAmount = 0.0;
    POItems?.map((POItem) => {
      totalInvoiceAmount += (POItem.invoiced_quantity * POItem.invoiced_price_per_unit);
    });
    return totalInvoiceAmount;
  }

  function getGrnItemsMap(grnItems) {
    let poItemIdToGrnItemMap = new Map();

    grnItems &&
      grnItems?.map((grnItem) => {
        poItemIdToGrnItemMap.set(grnItem.purchase_order_item_id, grnItem);
      });

    return poItemIdToGrnItemMap;
  }

  function getModifiedPOItems(poItemIdToGrnItemMap, purchaseOrderItems) {
    let finalPOItems = [];
    purchaseOrderItems?.map((poItem) => {
      if (poItemIdToGrnItemMap.has(poItem.id)) {
        const grnItem = poItemIdToGrnItemMap.get(poItem.id);

        

        finalPOItems.push({
          ...poItem,
          quantity_delivered: grnItem.delivered_quantity,
          price_per_unit: grnItem.grn_price_per_unit,
          grn_quantity: grnItem.grn_quantity,
          invoiced_quantity : grnItem.invoiced_quantity,
          invoiced_price_per_unit : grnItem.invoiced_price_per_unit,
        });
      }
    });
    return finalPOItems;
  }

  const handleConfirmGrnCTA = async (id,errorCallback) => {
    if(pdfBlob && grn.supplier_bill_image){

        const nodeBase64Str = pdfBlob.split(";base64,")[1];
        const nodeArrayBuffer = base64ToArrayBuffer(nodeBase64Str);

        fetch(grn.supplier_bill_image)
        .then((res) => {
            if (!res.ok) {
            errorCallback(new Error("Couldn't fetch PDF supplier bill document"));
            }

            res
            .arrayBuffer()
            .then(async (pdfArrayBuffer) => {

              try{
                    const promiseValue = await makeCombinedPDFDocument([nodeArrayBuffer, pdfArrayBuffer]);
                    const byteArray = new Uint8Array(promiseValue);
                    const blob = new Blob([byteArray], { type: 'application/pdf' });

                    const fileExtension = 'pdf';

                    // Creating a File object to simulate setting an extension
                    const fileWithExtension = new File([blob], `supplier_bill_image.${fileExtension}`, { type: 'application/pdf' });

                    let formData = new FormData();
                    formData.append("fileType", "supplier_bill_image");
                    formData.append("file", fileWithExtension);
                    uploadDocument(formData,id,handleSellerStatusUpdate)
                    

                }
                catch(err) {
                    // errorCallback(err);
                };
            })
            .catch((err) => {
                // errorCallback(err);
            });
        })
        .catch((err) => {
            // errorCallback(err);
        });

    } else if (pdfBlob) {
      //if supplier bill image not caputred then only save bill of supply in supplier bill image
      // if seller is farmer + unregistered in that case we not capture supplier bill image at time of GRN
      const nodeBase64Str = pdfBlob.split(";base64,")[1];
        const nodeArrayBuffer = base64ToArrayBuffer(nodeBase64Str);
        const promiseValue = await makeCombinedPDFDocument([nodeArrayBuffer]);
        const byteArray = new Uint8Array(promiseValue);
        let blob = new Blob([byteArray], { type: 'application/pdf' });
      
        const fileExtension = 'pdf';

        // Creating a File object to simulate setting an extension
        const fileWithExtension = new File([blob], `supplier_bill_image.${fileExtension}`, { type: 'application/pdf' });
        let formData = new FormData();
        formData.append("fileType", "supplier_bill_image");        
        formData.append("file", fileWithExtension);
        uploadDocument(formData,id,handleSellerStatusUpdate)
    }
  };


  
  const updateSellerStatusSuccessCallback = () => {
    setOpenGrnConfirmedModal(!openGrnConfirmedModal);
    setGenerateCn(false)
  };

  const closeConfirmGrnModal = () => {
    setOpenGrnConfirmedModal(false);
    if(source==="all"){
      navigate("/po/all")
    }
    else if (source===SOURCE_DD){
      navigate("/ddPo/all")
    }
    else{
      navigate("/grn/pending");
    }
  };
  
  useEffect(()=>{
    if(purchaseOrderItems.length>0){
        setShowInvoice(true)
    }
  },[purchaseOrderItems])

  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal)
  }

  useEffect(()=>{
    if(updateSellerApprovalStatus === STATUS_BEGIN || uploadDocumentStatus === STATUS_BEGIN){
      setShowButtonLoader(true)
    }
    else if(updateSellerApprovalStatus === STATUS_DONE || uploadDocumentStatus === STATUS_DONE){
      setShowButtonLoader(false)
    }
  },[updateSellerApprovalStatus,uploadDocumentStatus])


  useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return;
  }
  if(markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_BEGIN){
    setShowLoader(true);
  } else if (markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_DONE || markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus === STATUS_FAIL) {
    setOpenSuccessModel(true);
  }
  }, [markBulkBuyerOrderCompletedLinkedToDDPurchaseOrderStatus]);

  const checkEmailForFarmerOutlet = (email) => {
      if (window.localStorage.getItem("seller-type") &&
          window.localStorage.getItem("seller-type") === strings.farmerOutlet) {
          let invalidEmail = strings.blockedEmailHandleListForFarmer.map((handler) => {
            if (new RegExp(handler).test(email)) {
                return 1
            }
          })
          if (invalidEmail.includes(1)) {
              return true
          }
      }
      return false
  }

  const handleSellerStatusUpdate = (id, documentUrl) => {
    if (source === SOURCE_DD) {
      setShowLoader(true)
      updatePurchaseOrder({
        id: Number(purchaseOrderId),
        total_supplier_bill_amount : po.po_amount,
        supplier_bill_image : documentUrl,
      }, completeBos, handleUpdatePurchaseOrderError)
    } else {
      if (poGrnList[0]?.cn_data?.total_cn_amount !== 0){
        setShowToast(false)
        setInvoiceDocUrl(documentUrl)
        setGenerateCn(true);
      } else {
        updateSellerStatus({
                  entity_id: id,
                  entity_type: "PO_GRN_MAPPING",
                  seller_status: SELLER_STATUS_APPROVED,
                  supplier_bill: documentUrl,
                  account_id: window.localStorage.getItem("outlet_id")
              },
          updateSellerStatusSuccessCallback
      );}
    }
  };

  const completeBos = () => {
    if (buyerOrdersLinkedtoPO.length > 0) {
      let orderIds = buyerOrdersLinkedtoPO.map((bo) => bo.buyer_order_id);
      markBulkBuyerOrderCompletedLinkedToDDPurchaseOrder({
        order_ids : orderIds,
        purchase_order_id : Number(purchaseOrderId),
        warehouse_code : po.warehouse_code,
      });
    }
    setOpenSuccessModel(true);
  }

  const handleUpdatePurchaseOrderError = () =>{
    setErrorMsg("Error while updating purchase order - Please refresh and try again")
    toggleConfirmationModal()
    setShowToast(true)
    setTimeout(() => {
      navigate("/ddPo/all")
    },1500)
  }

  const handleCNCreationEvent = () => {
    updateEntityStatus("createAndGetCnStatus", STATUS_INIT);
    updateSellerStatus(
      {
        entity_id: grn.id,
        entity_type: "PO_GRN_MAPPING",
        seller_status: SELLER_STATUS_APPROVED,
        supplier_bill: invoiceDocUrl,
        account_id: window.localStorage.getItem("outlet_id"),
      },
      updateSellerStatusSuccessCallback
    );
  }

 const handleCNCreationFailureEvent = () => {
      setErrorMsg("Error while confirming grn")
      setGenerateCn(false)
      toggleConfirmationModal()
      setShowToast(true)
    }

  return (
    <React.Fragment>
      {generateCn && <GenerateCN 
      handleCNCreationEvent = {handleCNCreationEvent}
      handleCNCreationFailureEvent = {handleCNCreationFailureEvent}
      grnId = {grn.id}
      />}

      {showLoader ? (
        <Loader />
      ) : (
        <Wrapper>
          <WhiteWrapper>
            <div className="d-flex">
                <BackButton pathName={source==="all"? (`/grn/details/all/${purchaseOrderId}/${grnId}`) : source ===SOURCE_DD ? (`/ddPo/details/invoice/${purchaseOrderId}`)  : `/grn/details/pending/${purchaseOrderId}/${grnId}`} />
                <div className="d-flex flex-column ml-1 mr-4">
                    <span className="fs-18 fw-700">{grn.purchase_order_number}</span>
                    <span className="fs-12 fw-400 text-gray-cool mt-1">
                        {grn?.po_grn_items?.length} product
                        {grn?.po_grn_items?.length > 1 ? "s" : ""}
                    </span>
                </div>
            </div>
          </WhiteWrapper>
          <div className="d-flex justify-content-center mt-4 mbn-3">
            <span className="fs-12 fw-600 text-gray-cool">Invoice Preview</span>
          </div>

          {po && grn && purchaseOrderItems && showInvoice && poDisplayRef.current && (
            <ViewPDF htmlWithoutPDF={poDisplayRef.current.innerHTML} pdfBlob={pdfBlob} setPdfBlob={setPdfBlob}/>
          )}
          
       {grn.seller_status !== "APPROVED" && pdfBlob && (
          <WhiteWrapper className="flex px-5 bottomDiv">
            <Button
              className="fs-13 text-white fw-600"
              onClick={toggleConfirmationModal}
            >
              {grn?.dn_data?.total_dn_amount !== 0 ? (
                "Confirm GRN and Debit Note"
              ) : grn?.cn_data?.total_cn_amount !== 0 ? (
                "Confirm GRN and Generate CN"
              ) : (
                source === SOURCE_DD?(`Confirm Invoice`):(`Confirm GRN`)
              )}
            </Button>
            <div className="mt-4 text-gray-cool" dangerouslySetInnerHTML={{ __html: strings.pocContactDetails }}></div>
          </WhiteWrapper>
        )}
        </Wrapper>
      )}
      <SushiModal visible={openConfirmationModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/info_logo.svg" height="80px" width="80px" />
          <span className="fs-21 fw-700 my-4 text-align-center">
            {grn?.dn_data?.total_dn_amount !== 0 ? (
                strings.confirmationGrnAndDNModal
              ) :grn?.cn_data?.total_cn_amount !== 0 ? (
                strings.confirmationGrnAndCNModal
              ) : (
                strings.confirmationGrnModal
              )}
            </span>      
          {grn?.cn_data?.total_cn_amount === 0 && grn?.dn_data?.total_dn_amount === 0? (
              <CardWrapper showBorder={true} bgColor={"#F8F9FC"} className="d-flex flex-column align-items-center wd-100 mb-4 p-4">
              <span className="fs-18 fw-600">&#8377; { source ===SOURCE_DD? totalInvoiceAmount.toLocaleString("en-IN") : grn.grn_amount?.toLocaleString("en-IN")}</span>
              <span className="fs-14 fw-400 text-gray-cool mt-2">will be paid to you</span>
            </CardWrapper>
            ) :grn?.dn_data?.total_dn_amount != 0 ? (
              <CardWrapper showBorder={true} bgColor={"#F8F9FC"} className="d-flex flex-column align-items-center wd-100 mb-4 p-4">
              <>
                <span className="fs-18 fw-600 green-color">&#8377; {(source ===SOURCE_DD? totalInvoiceAmount.toLocaleString("en-IN") : grn.grn_amount - grn?.dn_data?.total_dn_amount).toLocaleString("en-IN")}</span>
                <span className="fs-14 fw-400 text-gray-cool mt-2 my-3">will be paid to you</span>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2">GRN Amount</span>
                  <span className="fs-14 fw-600 ml-6">+ &#8377; {grn.grn_amount?.toLocaleString("en-IN")}</span>
                </div>
                <div className="cn-table-class">
                  <td className="fs-14 fw-400 text-gray-cool mt-2 ">DN Amount</td>
                  <span className="fs-14 fw-600 ml-6">- &#8377; {grn?.dn_data?.total_dn_amount.toLocaleString("en-IN")}</span>
                </div>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2 ">Total Amount</span>
                  <span className="fs-14 fw-600 ml-6">&#8377; {(grn.grn_amount - grn?.dn_data?.total_dn_amount).toLocaleString("en-IN")}</span>
                </div>
              </>
              </CardWrapper>
            ): 
            (
              <CardWrapper showBorder={true} bgColor={"#F8F9FC"} className="d-flex flex-column align-items-center wd-100 mb-4 p-4">
              <>
                <span className="fs-18 fw-600 green-color">&#8377; {(source ===SOURCE_DD? totalInvoiceAmount.toLocaleString("en-IN") : grn.grn_amount - grn?.cn_data?.total_cn_amount).toLocaleString("en-IN")}</span>
                <span className="fs-14 fw-400 text-gray-cool mt-2 my-3">will be paid to you</span>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2">GRN Amount</span>
                  <span className="fs-14 fw-600 ml-6">+ &#8377; {grn.grn_amount?.toLocaleString("en-IN")}</span>
                </div>
                <div className="cn-table-class">
                  <td className="fs-14 fw-400 text-gray-cool mt-2 ">CN Amount</td>
                  <span className="fs-14 fw-600 ml-6">- &#8377; {grn?.cn_data?.total_cn_amount.toLocaleString("en-IN")}</span>
                </div>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2 ">Total Amount</span>
                  <span className="fs-14 fw-600 ml-6">&#8377; {(grn.grn_amount - grn?.cn_data?.total_cn_amount).toLocaleString("en-IN")}</span>
                </div>
              </>
              </CardWrapper>
            )}          
          <Button
            className="fs-13 text-white fw-600 mb-3"
            onClick={() => handleConfirmGrnCTA(grn.id)}
            disabled={showButtonLoader}
          >
            {showButtonLoader? 
            <Spinner color={"white"} />
            :
            "Yes, confirm"}
          </Button>
          <span className="fs-14 fw-500 text-red-500" onClick={toggleConfirmationModal}>No, go back</span>
        </FlexColumn>
      </SushiModal>
      <SushiModal visible={openGrnConfirmedModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/tick_logo.svg" height="80px" width="80px" />
          <span className="fs-22 fw-700 my-4">
            {grn?.cn_data?.total_cn_amount !== 0 ? (
                "GRN and CN Confirmed "
              ) : (
                "GRN Confirmed "
              )}
          </span>
          <span
            className="fs-14 fw-500 text-red-500"
            onClick={closeConfirmGrnModal}
          >
            {" "}
            Okay
          </span>
        </FlexColumn>
      </SushiModal>

      {/*Invoice HTML String */}
      <div className="hide-pdf-container" ref={poDisplayRef}>
        <div
          style={{
            width: "550px",
            textAlign: "center",
            fontSize: "20px",
            paddingBottom: "12px"
          }}
        >
          <strong>{po.seller_info?.organization_name}</strong>
        </div>

        <div
            style={{
              width: "550px",
              textAlign: "center",
              borderWidth: "1px 1px 1px 1px",
              borderStyle: "solid",
              fontSize: "12px",
            }}
          >
            <strong>{source === SOURCE_DD ? (`Invoice`) : (`Invoice/Tax`)}</strong>
          </div>

        <div style={{ width: "550px" }} id="entry-po-display">
          <div
            style={{
              width: "550px",
              textAlign: "left",
              borderWidth: "0px 1px 0px 1px",
              borderStyle: "solid",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Outlet Name :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>{outletName}</strong>
              </span>
            </div>


            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Phone number :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>{po?.seller_info?.phone_number}</strong>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "4px"
              }}
            >
              <span
                style={{ width: "30%", fontSize: "14px", marginLeft: "10px" }}
              >
                {" "}
                Email :
              </span>
              <span style={{ width: "70%", fontSize: "14px" }}>
                <strong>{!checkEmailForFarmerOutlet(po?.seller_info?.email) ? po?.seller_info?.email : "-"}</strong>
              </span>
            </div>


          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              flexDirection: "column",
              border: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              style={{ width: "550px", display: "flex", flexDirection: "row" }}
            >
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Invoice Number
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{grn.supplier_bill_number}</strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Order Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>
                    {moment(new Date(po.created_at))
                      .format("DD MMM, YYYY")
                      .toString()}
                  </strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Invoice Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong> 
                  {source === SOURCE_DD ?
                    (moment(new Date())
                      .format("DD MMM, YYYY")
                      .toString())
                    :(moment(new Date(grn.grn_completed_on))
                      .format("DD MMM, YYYY")
                      .toString())}
                  </strong>
                </div>
              </div>
              <div style={{ padding: "4px", flex: 1 }}>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Delivery Date
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>
                  {source === SOURCE_DD ?
                    (moment(new Date())
                      .format("DD MMM, YYYY")
                      .toString())
                    :(moment(new Date(grn.grn_completed_on))
                      .format("DD MMM, YYYY")
                      .toString())}
                  </strong>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "550px",
                display: "flex",
                flexDirection: "row",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Place of Supply
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{po.warehouse_details?.state}</strong>
                </div>
              </div>
              <div
                style={{ borderRight: "1px solid", padding: "4px", flex: 1 }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Purchase Order Number
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong>{po.purchase_order_number}</strong>
                </div>
              </div>
              <div style={{ borderRight: "0px", padding: "4px", flex: 1 }}>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "5px",
                    fontSize: "12px",
                  }}
                >
                  Payment Status
                </div>
                <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <strong> - </strong>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              flexDirection: "column",
              textAlign: "left !important",
            }}
          >
            <div
              style={{
                width: "550px",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  Bill From :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <strong>{po.seller_info?.outlet_name}</strong>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  Address :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  {po.seller_info?.address},{" "}
                  <span style={{ fontSize: "12px" }}>
                    {po.seller_info?.city}
                  </span>
                  ,{" "}
                  <span style={{ fontSize: "12px" }}>
                    {po.seller_info?.state}
                  </span>
                  -
                  <span style={{ fontSize: "12px" }}>
                    {po.seller_info?.zip_code}
                  </span>
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px"}}>
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  PAN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {po.seller_info?.transaction_document_type === "pan" ? po.seller_info?.transaction_document_number : "-"}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  GSTIN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {po.seller_info?.gstin !== "" ? po.seller_info?.gstin : "-"}
                </span>
              </div>
            </div>

            <div
              style={{
                width: "550px",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "none",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  paddingBottom: "1px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  Bill To :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <strong> {po.warehouse_details?.name} </strong>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  Address :
                </span>
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <span style={{ fontSize: "12px" }}>
                    {po.warehouse_details?.billing_address}
                  </span>
                  ,
                  <span style={{ fontSize: "12px" }}>
                    {po.warehouse_details?.city}
                  </span>
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  GSTIN :
                </span>
                <span style={{ fontSize: "12px" }}>
                  {po.warehouse_details?.gstin_bill}
                </span>
              </div>
            </div>
            
            {source === SOURCE_DD ?
              (<div
                className="invoice-div"
                rowSpan={5}
                valign="top"
              >
                <div
                  className="invoice-sub-div"
                >
                  <span
                    className="invoice-key-span"
                  >
                    {" "}
                    Shipped From :
                  </span>
                  
                  <strong className="wd-70 fs-12">
                    {" "}
                    {po.seller_info?.outlet_name}{" "}
                  </strong>
                  
                </div>

                <div
                 className="invoice-sub-div"
                >
                  <span
                   className="invoice-key-span"
                  >
                    {" "}
                    Address :
                  </span>
                  
                  <span className="wd-70 fs-12">
                    <span className="fs-12">
                    {po.seller_info?.address}
                    </span>
                    ,
                    <span className="fs-12">
                      {po.seller_info?.city}
                    </span>
                  </span>
                  
                </div>

                <div
                  className="invoice-sub-div"
                >
                  <span
                    className="invoice-key-span"
                  >
                    {" "}
                    GSTIN :
                  </span>
                  {po.order_tag === PO_ORDER_TAG ? (
                    <span className="fs-12">
                      {po.seller_info?.gstin}
                    </span>
                  ) : (
                    <span className="fs-12">
                      {po.warehouse_details?.gstin_ship}
                    </span>
                  )}
                </div>
              </div>)
              :
              (``)}

            <div
              style={{
                width: "550px",
                borderWidth: "1px 1px 1px 1px",
                borderStyle: "solid",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "none",
              }}
              rowSpan={5}
              valign="top"
            >
              <div
                style={{
                  paddingBottom: "1px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  Shipped To :
                </span>
                
                <strong style={{ width: "70%", fontSize: "12px" }}>
                  {" "}
                  {source === SOURCE_DD ?
                    (po.buyer_outlet_details?.name)
                    :(po.warehouse_details?.name)}{" "}
                </strong>
                
              </div>

              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  Address :
                </span>
                
                <span style={{ width: "70%", fontSize: "12px" }}>
                  <span style={{ fontSize: "12px" }}>
                  {source === SOURCE_DD ?
                    (po.buyer_outlet_details?.shipping_address)
                    :(po.warehouse_details?.shipping_address)}
                  </span>
                  ,
                  <span style={{ fontSize: "12px" }}>
                    {source === SOURCE_DD ?
                    (po.buyer_outlet_details?.shipping_city)
                    :(po.warehouse_details?.city)}
                  </span>
                </span>
                
              </div>

              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "2px",
                }}
              >
                <span
                  style={{ width: "30%", fontSize: "12px", marginLeft: "10px" }}
                >
                  {" "}
                  GSTIN :
                </span>
                {po.order_tag === PO_ORDER_TAG ? (
                  <span style={{ fontSize: "12px" }}>
                    {po.buyer_outlet_details?.shipping_gstin}
                  </span>
                ) : (
                  <span style={{ fontSize: "12px" }}>
                    {po.warehouse_details?.gstin_ship}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "550px",
              display: "flex",
              fontSize: "12px",
              borderTop: "1px solid",
              borderRight: "1px solid",
              paddingRight: "1px",
            }}
          >
            <div
              style={{
                width: "8%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 1px",
                borderStyle: "solid",
                borderTop: "none",
              }}
            >
              <strong>Sl No.</strong>
            </div>
            <div
              style={{
                width: "25%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Product Name</strong>
            </div>
            <div
              style={{
                width: "12%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>HSN</strong>
            </div>

            <div
              style={{
                width: "14%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>{source ===SOURCE_DD? `Qty. Ord.`: `Qty. Del.`}</strong>
            </div>

            <div
              style={{
                width: "14%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Price Per Unit</strong>
            </div>
            <div
              style={{
                width: "12%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 1px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>UoM</strong>
            </div>

            <div
              style={{
                width: "15%",
                fontSize: "12px",
                textAlign: "center",
                borderWidth: "0 0px 0 0px",
                borderStyle: "solid",
              }}
            >
              <strong>Total</strong>
            </div>
          </div>

          {purchaseOrderItems &&
            purchaseOrderItems?.map((item, index) => (
              <div
                className="keep-together"
                style={{
                  width: "550px",
                  display: "flex",
                  fontSize: "12px",
                  borderTop: "1px solid",
                  borderRight: "1px solid",
                  paddingRight: "1px",
                }}
              >
                <div
                  style={{
                    width: "8%",
                    borderWidth: "0 1px 0 1px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                    borderTop: "none",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    width: "25%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.product_name}
                </div>
                <div
                  style={{
                    width: "12%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                    wordBreak: "break-all",
                  }}
                >
                  {item.hsn_code}
                </div>
                <div
                  style={{
                    width: "14%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {source ===SOURCE_DD? item.invoiced_quantity : item.grn_quantity}
                </div>
                <div
                  style={{
                    width: "14%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {source ===SOURCE_DD? item.invoiced_price_per_unit : item.price_per_unit}
                </div>
                <div
                  style={{
                    width: "12%",
                    borderWidth: "0 1px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  {item.buy_measurement_unit}
                </div>
                <div
                  style={{
                    width: "15%",
                    borderWidth: "0 0px 0 0px",
                    fontSize: "11px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  &#8377; {" "} {source ===SOURCE_DD? (item.invoiced_quantity * item.invoiced_price_per_unit).toFixed(2)  : (item.grn_quantity * item.price_per_unit).toFixed(2)}
                </div>
              </div>
            ))}

          <div
            style={{
              width: "550px",
              display: "flex",
              fontSize: "11px",
              borderRight: "1px solid",
              paddingRight: "1px",
            }}
          >
            <div
              style={{ width: "8%", border: "1px solid", borderStyle: "solid" }}
            />
            <div
              style={{
                width: "25%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                fontSize: "11px",
              }}
            >
              <span style={{ fontSize: "12px", marginLeft: "5px" }}>Total</span>
            </div>
            <div
              style={{
                width: "12%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "14%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "14%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "12%",
                border: "1px solid",
                borderStyle: "solid",
                borderLeft: "none",
              }}
            />
            <div
              style={{
                width: "15%",
                border: "1px solid",
                borderRight: "0px",
                borderStyle: "solid",
                borderLeft: "none",
                textAlign: "right",
              }}
            >
              <strong style={{ fontSize: "12px" }}>&#8377; {" "}{source ===SOURCE_DD? totalInvoiceAmount.toFixed(2)  : grn.grn_amount}</strong>
            </div>
          </div>
          <div
            style={{
              width: "550px",
              display: "flex",
              padding: "5px 0px",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
            }}
          >
            <div style={{ width: "80%", fontSize: "12px", marginLeft: "5px" }}>
              {" "}
              <span style={{ fontSize: "12px" }}>
                Amount Chargeable (in words)
              </span>{" "}
              <br />
              <strong>
                INR
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  {source ===SOURCE_DD? number2text(totalInvoiceAmount.toFixed(2),'',true):number2text(grn.grn_amount,'',true)}  
                </span>
              </strong>
            </div>
            <div
              style={{
                width: "20%",
                textAlign: "right",
                fontSize: "12px",
                marginRight: "5px",
              }}
            >
              {" "}
              <span className="fs-12">E. &amp; O.E</span>
            </div>
          </div>
          <div className="d-flex">
          <span className="fs-12 text-align-center wd-100 mt-4">This is a computer generated invoice and does not need signatures.</span>
          </div>
        </div>
      </div>
      <SushiModal visible={openSuccessModel} isCentered>
        <FlexColumn className="align-items-center p-5">
            <div className="align-self-end" onClick={closeConfirmGrnModal} > <Cross /> </div>
            <img src="/logos/tick_logo.svg" height="80px" width="80px" />
            <span className="fs-22 fw-700 my-4">Invoice uploaded</span>
            <span
                className="fs-14 fw-500 text-red-500"
                onClick={closeConfirmGrnModal}
            >
                {" "}
                Okay
            </span>
        </FlexColumn>
      </SushiModal>
      {showToast?
        <ShowToast
        errorMsg={errorMsg}
        />
        
        :
        <></>
        }
    </React.Fragment>
  );
};

export default Invoice;
