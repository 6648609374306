import moment from "moment/moment";
import number2text from "number2text";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewPDF from "utils/ViewPDF";
import useVendorStore from "../HomePage/useVendorStore";
import { Loader } from "../Common/Loader";
import { strings } from "utils/constants/strings";
import { Wrapper } from "../Common/styles.common";
import {
  base64ToArrayBuffer,
  makeCombinedPDFDocument,
} from "operations/operations";
import useCommonStore from "../Common/useCommonStore";
import MapCreditNoteData from "./mapCreditNoteData";
import {STATUS_DONE,STATUS_FAIL } from "utils/constants/others";

const GenerateCN = (props) => {
  const createAndGetCnData = useVendorStore(
    (state) => state.createAndGetCnData
  );
  const [pdfBlob, setPdfBlob] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const cnData = useVendorStore((state) => state.CnData);
  const [handleUploadCN, setHandleUploadCN] = useState(false);
  const poDisplayRef = useRef();
  const uploadFileToS3 = useCommonStore((state) => state.uploadFileToS3);
  const [cnHtmlData, setCnHtmlData] = useState();
  const createAndGetCnStatus = useVendorStore((state)=> state.createAndGetCnStatus);
  const [isCNCreated, setIsCNCreated] = useState(false);

  useEffect(() => {
     if (createAndGetCnStatus === STATUS_FAIL) {
        props.handleCNCreationFailureEvent();
      }
     if (createAndGetCnStatus === STATUS_DONE) {
        setIsCNCreated(true);
     }
  }, [createAndGetCnStatus]);

  useEffect(() => {
    if (props.grnId) {
      createAndGetCnData({ po_grn_mapping_id: parseInt(props.grnId, 10) });
    }
  }, [props.grnId]);

  useEffect(() => {
    if (pdfBlob) {
      setHandleUploadCN(true);
    }
  }, [pdfBlob]);

  useEffect(() => {
    if (handleUploadCN) {
      handleConfirmUploadCN();
    }
  }, [handleUploadCN]);

  const handleConfirmUploadCN = async (id, errorCallback) => {
    if (pdfBlob) {
      const nodeBase64Str = pdfBlob.split(";base64,")[1];
      const nodeArrayBuffer = base64ToArrayBuffer(nodeBase64Str);
      try {
        const promiseValue = await makeCombinedPDFDocument([nodeArrayBuffer]);
        const byteArray = new Uint8Array(promiseValue);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        uploadFileToS3(
          blob,
          cnData.cn_path,
          props.handleCNCreationEvent,
          props.handleCNCreationFailureEvent
        );
      } catch (err) {
        props.handleCNCreationFailureEvent();
      }
    }
  };

  return (
    <React.Fragment>
      {showLoader ? (
        <Loader />
      ) : (
        <Wrapper>
          {cnData && cnHtmlData && (
            <ViewPDF
              htmlWithoutPDF={cnHtmlData}
              pdfBlob={pdfBlob}
              setPdfBlob={setPdfBlob}
            />
          )}
        </Wrapper>
      )}
      {isCNCreated && cnData && createAndGetCnStatus === STATUS_DONE &&(
        <MapCreditNoteData
          cnData={cnData}
          poDisplayRef={poDisplayRef}
          setCnHtmlData={setCnHtmlData}
        />
      )}
    </React.Fragment>
  );
};

export default GenerateCN;
