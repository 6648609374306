import React, { useEffect, useState } from "react";
import { Circle } from "./styles.po";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Card, SeparatorLine } from "../Common/styles.common";
import {InvoiceButton} from "../GRN/styles.grn";
import {DownloadInvoice,DownloadPDF,getPdfBytes} from "../../../operations/operations";
import { v4 as uuidv4 } from "uuid";
import useCommonStore from "../Common/useCommonStore";
import Toast from "sushiweb/Toast";
import ShowToast from "../Common/Toast";

const PoCard = (props) => {
  const po = props.po;
  const [tonnage,setTonnage] = useState(0)
  const navigate = useNavigate();
  const uploadDocs = useCommonStore((state)=> state.uploadDocs);
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  const downloadPO =() => {
    if(po.po_document_url){
        DownloadPDF(po.po_document_url, `PO-${po.purchase_order_number}`, (error) => {
          setShowToast(true);
          setErrorMsg("Something went wrong downloading the PO document")
        });
        if(window?.downloadFileMessageHandler){
          window.downloadFileMessageHandler.postMessage(po.po_document_url);
        }
    } else {
      setShowToast(true);
      setErrorMsg("No PO document found")
    }
  }


  const handleViewDetailsCTA = () => {
    if(props.source==="all"){
      navigate(`/po/details/all/${po.id}`)
    }
    else{
      navigate(`/po/details/pending/${po.id}`);
    }
  };

  useEffect(()=>{
    calculateTonnage();
  },[])

  const calculateTonnage =()=>{
    let total = 0;
    for(const item of po.purchase_order_items){
      total=total+ item.quantity_ordered* item.weight_per_packet
    }
    setTonnage(total)
  }

  const handleCallCTA = () =>{
    props.setPhoneNumber(po.created_by_phone_no);
    props.setPoCreator(po.created_by_user_name);
    props.setOpenCallModal(true);
  }

  return (
    <React.Fragment>
      <Card className="mt-4 mx-4">
        <div className="d-flex justify-content-between px-4">
          <div className="d-flex flex-column">
            <span className="fs-18 fw-700">{po.purchase_order_number}</span>
            <span className="fs-12 fw-400 text-gray-cool mt-1">
              {" "}
              {po.purchase_order_items.length} product
              {po.purchase_order_items.length > 1 ? "s" : ""}
              {" "}•{" "}
              {tonnage>999? (tonnage/1000).toFixed(2): tonnage.toFixed(2)} {tonnage>999 ? "tonne":"kg"}
            </span>
          </div>
          <img
            src={"../logos/call_logo.svg"}
            onClick={handleCallCTA}
          />
        </div>
        <SeparatorLine />
        <div className="d-flex flex-column ml-4 mr-4">
          <div className="d-flex align-items-center">
            <Circle className="mr-3">
              <img src="../logos/rupee_logo.svg" height="16px" width="15px" />
            </Circle>
            <span className="fs-14 text-gray-cool">&#8377;</span>
            <span className="fs-14 fw-400 text-gray-cool">
              {po.po_amount.toLocaleString("en-IN")} PO Amount
            </span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <Circle className="mr-3">
              <img
                src="../logos/delivery_logo.svg"
                height="16px"
                width="15px"
              />
            </Circle>
            <span className="fs-14 fw-400 text-gray-cool">
              Delivery by{" "}
              {moment(new Date(po.expected_delivery_date))
                .format("DD MMM, YYYY")
                .toString()}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Circle className="mr-3">
              <img
                src="../logos/location_logo.svg"
                height="16px"
                width="15px"
              />
            </Circle>
            <span className="fs-14 fw-400 text-gray-cool wd-po-card">
              {po.warehouse_details.name}
            </span>
          </div>
        </div>
        <SeparatorLine />
        <div className="d-flex mr-5">
          {po.po_document_url !== "" ? 
          (<InvoiceButton className="ml-5" onClick={downloadPO}>
            <span className="fs-13 fw-500 text-red">Download PO</span>
          </InvoiceButton>) :(<></>)}
          <Button className="ml-5" onClick={handleViewDetailsCTA}>
            <span className="fs-13 fw-500 text-white">View PO Details</span>
          </Button>
        </div>
        
      </Card>
      {showToast?
        <ShowToast
        errorMsg={errorMsg}
        />
        :
        <></>
        }
    </React.Fragment>
  );
};

export default PoCard;
