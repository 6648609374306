import { create } from "zustand";
import produce from "immer";
import { devtools } from "zustand/middleware";
import _get from "lodash-es/get";
import { api } from "network/network";
import { APIendpoints } from "utils/constants/APIendpoints";
import { API_HOST_POD } from "config/api.config";

let useAuthStore = create(
  devtools((set, get) => ({
    authDetails: {
      isLoggedIn: false,
      isAuthorized: false,
      isAuthenticationInProgress: false,
    },
    loginDetails: {
      sendOtp: {
        begin: false,
        done: false,
        fail: false,
      },
      validateOtp: {
        begin: false,
        done: false,
        fail: false,
      },
    },
    error: null,

    getIsUserLoggedIn: () => !!get().authDetails.isLoggedIn,
    getIsUserAuthorized: () => !!get().authDetails.isAuthorized,
    getIsAuthenticationInProgress: () =>
      !!get().authDetails.isAuthenticationInProgress,
    setLoggedIn: (val) => {
      set(
        produce((state) => {
          state.authDetails.isLoggedIn = val;
          state.authDetails.isAuthorized = val;
        })
      );
    },
    setAuthorized: (val) =>
      set(
        produce((state) => {
          state.authDetails.isAuthorized = val;
        })
      ),
    setIsAuthenticationInProgress: (val) => {
      set(
        produce((state) => {
          state.authDetails.isAuthenticationInProgress = val;
        })
      );
    },

    setBegin: (key) =>
      set(
        produce((state) => {
          state.loginDetails[key].begin = true;
          state.loginDetails[key].done = false;
          state.loginDetails[key].fail = false;
        })
      ),
    setDone: (key) =>
      set(
        produce((state) => {
          state.loginDetails[key].begin = false;
          state.loginDetails[key].done = true;
          state.loginDetails[key].fail = false;
        })
      ),
    setFail: (key) =>
      set(
        produce((state) => {
          state.loginDetails[key].begin = false;
          state.loginDetails[key].done = false;
          state.loginDetails[key].fail = true;
        })
      ),

    setError: (err) =>
      set((state) => {
        state.error = err;
      }),

    sendOtp: async (request) => {
      get().setBegin("sendOtp");
      api
        .post(API_HOST_POD, APIendpoints.sendOtp, request)
        .then(() => {
          get().setDone("sendOtp");
        })
        .catch((error) => {
          set({ error: _get(error, ["response", "data", "error"], {}) });
          get().setFail("sendOtp");
        });
    },

    validateOtp: async (request) => {
      get().setBegin("validateOtp");
      api
        .post(API_HOST_POD, APIendpoints.validateOtp, request)
        .then(({ data, headers }) => {
          window.localStorage.setItem("outlet_id", data.response.outlet_id);
          window.localStorage.setItem("outlet_name", data.response.outlet_name);
          window.localStorage.setItem("is_new_seller_hub_login_flow_enabled", data.response.is_new_seller_hub_login_flow_enabled);
          if (headers) {
            let token = headers.authorization;
            window.localStorage.setItem("token", token);
          }
          get().setLoggedIn(true);

          get().setDone("validateOtp");
        })
        .catch((error) => {
          set({ error: _get(error, ["response", "data", "error"], {}) });
          get().setFail("validateOtp");
        });
    },
  }))
);

export default useAuthStore;
