import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  // You can continue writing global styles here
  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-weight: 300;
    box-sizing: border-box;
    font-family: OurLexend, Helvetica, sans-serif;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-moz-scrollbar {
    width: 0;
    height: 0;
  }
  .overflow-scrollbar-visible::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .overflow-scrollbar-visible::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .overflow-scrollbar-visible::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #696969;
    opacity: 0.6;
  }
  .overflow-scrollbar-visible::-webkit-scrollbar-thumb:hover {
    background: #696969;
    opacity: 0.6;
  }
  body,
  button,
  input,
  input::placeholder {
    font-family: "OurLexend-Regular";
  }
  *,
  *:after,
  *:before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }
  *:focus,
  *:active {
    outline: none;
  }
  div,
  p,
  span {
    font-size: 1.6rem;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /**
* Reducing Motion to Improve Accessibility. Animations, zooming, and panning can be problematic for people who have vestibular disorders 
*/
  @media screen and (prefers-reduced-motion: reduce) {
    * {
      animation-play-state: paused !important;
      transition: none !important;
      scroll-behavior: auto !important;
    }
  }
  @font-face {
    font-family: "OurLexend-Regular";
    src: url("/fonts/OurLexend-Regular.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Medium";
      src: url("/fonts/OurLexend-Medium.ttf") format("truetype");
      font-weight: bold;
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-SemiBold";
      src: url("/fonts/OurLexend-SemiBold.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Bold";
      src: url("/fonts/OurLexend-Bold.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-ExtraBold";
      src: url("/fonts/OurLexend-ExtraBold.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Light";
      src: url("/fonts/OurLexend-Light.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Regular";
      src: url("/fonts/OurLexend-Regular.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Medium";
      src: url("/fonts/OurLexend-Medium.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-SemiBold";
      src: url("/fonts/OurLexend-SemiBold.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }

  @font-face {
      font-family: "OurLexend-Bold";
      src: url("/fonts/OurLexend-Bold.ttf") format("truetype");
      font-display: swap;
      font-style: normal;
  }
  :root {
    --toastify-font-family: Lexend;
    --toastify-toast-width: 400px;
  }
`;
